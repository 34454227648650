import React from 'react';
import ResizeTable from './resizetable';

const data = [];

const generateData = columns => {
  data.splice(0);
  if(columns != null)
  {
    for (let i = 0; i < columns.length; i++) {
      const column = columns[i];
      data.push({ 
        key: i.toString(),
        name: column.text, 
        joblinks: column.jobname });
    }
  }
};

class ColumnUsageTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        data: data,
        columns: [
            {
              title: props.columnTitle,
              dataIndex: 'name',
              key: 'name',
              width: props.tableWidth*0.3,
              sorter: (a, b) => a.name.localeCompare(b.name),
            },
            {
              title: 'Hot Jobs',
              dataIndex: 'joblinks',
              key: 'joblinks',
              width: props.tableWidth*0.7,
              render: (text, record) => {
                var joblinks = record.joblinks;
                if(joblinks != null && joblinks.length>0)
                {
                  var links = JSON.parse(joblinks);
                  
                  return links.map(link=>{
                      var index = link.indexOf(":");
                      var jobName = index === -1 ? link : link.substring(0, index);
                      var searchtext = this.assetName+","+record.name;
                      var httplink = `https://scriptlibrary.azurewebsites.net/CosmosScript?searchText=${searchtext}+jobname:${jobName}&source=main&pageNumber=1&tenant=all&orderByLatest=on`;
                      var displayName = link.replace(":::", "").replace("CC_", "C2C_")
                      return (
                          <span><a href={httplink} target={"_blank"}>{displayName}</a><br></br></span>
                        )
                  });
                }
              }
            }
          ]
    };
  }

  render() {
    var words = this.props.words;
    this.assetName = this.props.assetName;
    
    generateData(words);

    return (
      <ResizeTable
        data={this.state.data}
        columns={this.state.columns}
        pageSize={this.props.pageSize}
        position={'bottom'}
        />
    );
  }
}

export default ColumnUsageTable;
import React from 'react';
import SearchTree from './tree';
import TabsPanel from './tabs';
import 'antd/dist/antd.css';
import './App.css';
import { Layout, Spin, Input, List, Button, Tooltip } from 'antd';
import { ImportOutlined, SmileOutlined } from '@ant-design/icons';
import { UserAgentApplication } from 'msal';
import Feedback from './feedback';
import Tools from './data-registry';

const { Header, Content, Sider } = Layout;
const { Search } = Input;

const clientId = 'e8e5d4cc-a967-4de6-8d63-889e5e4c775b';
const authority = 'https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47';

class App extends React.Component {

  constructor(props) {
    super(props);

    this.userAgentApplication = new UserAgentApplication({
      auth: {
        clientId: clientId,
        authority: authority
      }
      // ,
      // cache: {
      //   cacheLocation: "localStorage",
      //   storeAuthStateInCookie: true
      // }
    });

    this.login();

    this.state = {
      detailData: {
        'name': 'C2CMasterView',
        'description': 'description',
        'owner': 'owner',
        'startTime': 'startTime',
        'endTime': 'endTime',
        'columns': [],
        'type': 'AssetType',
        'querySample': 'QuerySample',
        'primaryKeys': 'PrimaryKeys',
        'tags': 'Tags',
        'parameters': []
      },
      words: [],
      filters: [],
      relations: [],
      searchResultList: [],
      searchValue: null,
      searchValueInDetail: null,
      isAuthenticated: false,
      user: null,
      accessToken: null,
      isAuthorized: true
    };
  }

  async login() {
    try {
      const loginRequest = {
        scopes: ['user.read', 'Directory.Read.All']
      }

      function authCallback(error, response) {
      }

      if (!this.userAgentApplication.getAccount()) {
        await this.userAgentApplication.handleRedirectCallback(authCallback);
        await this.userAgentApplication.loginRedirect(loginRequest);
      }
    }
    catch (err) {
      console.error("[App::login] ERROR:", err);
    }
  }

  logout() {
    this.userAgentApplication.logout();
  }

  async checkGroups(accessToken) {
    // This group id is Bing Ads All FTE
    var content = "{\"groupIds\": [\"ba6a5b92-3175-4b02-a720-0cbf03e36647\",\"6f1f2fcf-8ad7-4f99-a2d0-b9a9cf159198\",\"59e4c296-8680-4e8c-b7ef-f2918c649cc4\"]}";
    var path = 'https://graph.microsoft.com/v1.0/me/checkMemberGroups';
    // This is only for accessToken check. If a valid accessToken is provided then the response should always be true.
    let response = await fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + accessToken
      },
      body: content
    });
    let data = await response.json();
    if (data.value.length > 0) {
      return true;
    }
    return false;
  }

  async getUserProfile() {
    try {
      var accounts = this.userAgentApplication.getAccount();
      if (accounts == null)
        return;
      console.log("[App::getUserProfile] account: " + accounts.userName);
      if (this.state.isAuthorized == null) {
        var request = {
          scopes: ['https://graph.microsoft.com/Directory.Read.All']
        };
        var token = await this.userAgentApplication.acquireTokenSilent(request);
        var accessToken = token.accessToken;
        var isAuthorized = await this.checkGroups(accessToken);
        this.setState({ isAuthorized: isAuthorized });
      }

      if (!this.state.isAuthorized)
        return;
      request = {
        scopes: ['fbe6d0fe-2f39-41d8-ae85-9e2183042f68/.default']
      };
      token = await this.userAgentApplication.acquireTokenSilent(request);
      accessToken = token.accessToken;
      // console.log("[App::getUserProfile] access token: " + accessToken);
      if (this.state.accessToken !== accessToken) {
        this.setState({
          isAuthenticated: true,
          user: accounts.userName,
          accessToken: accessToken
        });
      }
    }
    catch (err) {
      console.error("[App::getUserProfile] ERROR:", err);
    }
  }

  componentWillMount() {
    this.changeItem('/api/DataAsset?type=CosmosView&name=C2CMasterView.view');
  }

  async changeItem(path, searchValueInDetail = null) {
    console.log("[App::changeItem] path:", path)
    await this.getUserProfile();
    this.setState({ detailData: { 'name': 'C2CMasterView' } });
    var fullpath = process.env.REACT_APP_API_URL + path; // https://demandcatalogapinew.sa2.p.azurewebsites.net + /xxxxxxx
    fetch(fullpath, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.state.accessToken
      }
    }).then(
      res => res.json()
    ).then(response => {
      console.log("[App::changeItem] response:", response);
      this.setState({
        detailData:
        {
          'name': response.Name,
          'description': response.Description,
          'owner': response.Owner,
          'startTime': response.StartTime,
          'endTime': response.EndTime,
          'columns': response.Columns,
          'type': response.AssetType,
          'querySample': response.QuerySample,
          'primaryKeys': response.PrimaryKeys == null ? null : response.PrimaryKeys.join(', '),
          'tags': response.Tags == null ? null : response.Tags.join(', '),
          'parameters': response.Parameters,
          'rate': response.Rate,
          'fidelity': response.Fidelity,
          'childrenNames': response.ChildrenNames
        }
      });
      this.setState({ searchResultList: [] });
      this.setState({ searchValue: null });
      this.setState({ searchValueInDetail: searchValueInDetail });

    }).catch((err) => {
      console.error("[App::changeItem] ERROR:", err);
    });
  }

  getRelationShip(assetName) {
    console.log("[App::getRelationShip] path:", assetName)
    var path = process.env.REACT_APP_API_URL + "/api/DataAssetRelation?assetName=" + assetName;
    fetch(path, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.state.accessToken
      }
    }).then(
      res => res.json()
    ).then(response => {
      this.setState({ relations: response });
    }).catch((err) => {
      console.error("[App::getRelationShip] ERROR:", err);
    })
  }

  getWordsPopularity(assetName) {
    console.log("[App::getWordsPopularity] path:", assetName)
    var path = process.env.REACT_APP_API_URL + "/api/DataAssetRelation/Column?assetName=" + assetName;
    fetch(path, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.state.accessToken
      }
    }).then(
      res => res.json()
    ).then(response => {
      console.log("[App::getWordsPopularity] response:", response);
      var words = [];
      for (let i = 0; i < response.length; i++) {
        const node = response[i];
        words[i] = {
          'text': node.Name,
          'value': node.Count,
          'jobname': node.JobName,
          'assetname': assetName
        };
      }
      this.setState({ words: words });
    }).catch((err) => {
      console.error("[App::getWordsPopularity] ERROR:", err);
    })
  }

  getFilterPopularity(assetName) {
    console.log("[App::getFilterPopularity] path:", assetName)
    var path = process.env.REACT_APP_API_URL + "/api/DataAssetRelation/Filter?assetName=" + assetName;
    fetch(path, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.state.accessToken
      }
    }).then(
      res => res.json()
    ).then(response => {
      console.log("[App::getFilterPopularity] response:", response);
      var filters = [];
      for (let i = 0; i < response.length; i++) {
        const node = response[i];
        filters[i] = {
          'text': node.Name,
          'value': node.Count,
          'jobname': node.JobName,
          'assetname': assetName
        };
      }
      this.setState({ filters: filters });
    }).catch((err) => {
      console.error("[App::getFilterPopularity] ERROR:", err);
    })
  }

  getCustomizedC2SReport(assetName) {
    console.log("[App::getCustomizedC2SReport] path:", assetName)
    this.setState({ detailData: { 'name': 'Data Analysis Tools' } });
  }

  getRSAAdsDrop(assetName) {
    console.log("[App::getRSAAdsDrop] path:", assetName)
    this.setState({ detailData: { 'name': 'RSAAdsDrop' } });
  }

  getAdsDropDebugging(assetName) {
    console.log("[App::getAdsDropDebugging] path:", assetName)
    this.setState({ detailData: { 'name': 'AdsDropDebugging' } });
  }

  getSpendByOperation(assetName) {
    console.log("[App::getSpendByOperation] path:", assetName)
    this.setState({ detailData: { 'name': 'SpendByOperation' } });
  }

  search(text) {
    this.setState({ detailData: { 'name': 'C2CMasterView' } });
    this.setState({ searchResultList: [] });
    this.setState({ searchValue: null });
    var path = process.env.REACT_APP_API_URL + `/api/DataAsset/FullTextSearch?type=CosmosView&word=${text}&pageSize=100&pageIndex=0`;
    fetch(path, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.state.accessToken
      }
    }).then(
      res => res.json()
    ).then(response => {
      console.log("[App::search] response:", response);
      var searchResultList = [];
      for (let i = 0; i < response.length; i++) {
        const node = response[i];
        searchResultList[i] = {
          'assetName': node.AssetName,
          'path': node.Path,
          'content': node.Content
        };
      }
      this.setState({ searchResultList: searchResultList });
      this.setState({ searchValue: text });
    }).catch((err) => {
      console.error("[App::search] ERROR:", err);
    })
  }

  render() {
    var detail = this.state.detailData;
    var words = this.state.words;
    var filters = this.state.filters;
    var relations = this.state.relations;
    var userName = this.state.user;
    var accessToken = this.state.accessToken;
    var searchResultList = this.state.searchResultList;
    var showSearch = this.state.searchValue != null;

    var leftWidth = 300;
    var rightWidth = window.innerWidth - leftWidth;

    return (
      <Layout>
        <Header style={{ marginBottom: 0 }} theme="dark">
          <div style={{ marginLeft: -30 }}>
            <div style={{ float: 'left' }}>
              <span style={{ fontSize: 24, color: '#fff' }}>Ads Demand Data Catalog (Beta)</span>
            </div>
            <div style={{ float: 'right' }}>
              <Tools accessToken={accessToken} userName={userName} />
              {/* <DataRegistryTable accessToken={accessToken} userName={userName} /> */}
              <Search
                placeholder="Input search text"
                onSearch={value => this.search(value)}
                style={{ width: 300 }}
              />
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span style={{ fontSize: 20, color: '#fff' }}>Hello, {userName}&nbsp;&nbsp;</span>
              <Feedback accessToken={accessToken} userName={userName} />
            </div>

          </div>
        </Header>
        <Layout>
          <div >
            <Sider width={leftWidth}
              style={{
                overflow: 'auto',
                overflowX: 'auto',
                height: '91vh',
                position: 'fixed',
                left: 0,
                background: '#d9d9d9'
              }}
            >
              <SearchTree changeItem={key => { this.changeItem(key) }}
                getWordsPopularity={name => { this.getWordsPopularity(name) }}
                getFilterPopularity={name => { this.getFilterPopularity(name) }}
                getRelationShip={name => { this.getRelationShip(name) }}
                getRSAAdsDrop={name => { this.getRSAAdsDrop(name) }}
                getAdsDropDebugging={name => { this.getAdsDropDebugging(name) }}
                getSpendByOperation={name => { this.getSpendByOperation(name) }}
                getCustomizedC2SReport={name => { this.getCustomizedC2SReport(name) }}
                accessToken={accessToken} userName={userName} />
            </Sider>
          </div>
          <Layout style={{ marginLeft: 300 }}>
            <Content style={{ overflow: 'auto', overflowX: 'auto', height: '90vh', position: 'fixed' }}>
              <div style={{ padding: 24, background: '#nnn', width: rightWidth }}>
                {
                  showSearch ?
                    <List
                      header={<div>Find {searchResultList.length} result</div>}
                      pagination={{ pageSize: 5 }}
                      //itemLayout="horizontal"
                      dataSource={searchResultList}
                      renderItem={item => (
                        <List.Item >
                          <List.Item.Meta
                            title={<Button type="link" onClick={() => this.changeItem(item.path, this.state.searchValue)}>{window.ReplaceColor(item.assetName, this.state.searchValue)}</Button>}
                            // title={<a href="javascript:void(0);" onclick={this.changeItem(item.path)}>{this.ReplaceColor(item.assetName)}</a>}
                            description={window.ReplaceColor(item.content, this.state.searchValue)}
                          />
                        </List.Item>
                      )}
                    />
                    :
                    (
                      detail.name === 'C2CMasterView' ?
                        <div style={{ textAlign: "center", marginLeft: "auto", marginRight: "auto" }}>
                          <Spin tip="Loading..." />
                        </div>
                        :
                        <TabsPanel detail={detail} words={words} filters={filters}
                          relations={relations} accessToken={accessToken} userName={userName} searchValue={this.state.searchValueInDetail} />
                    )
                }
              </div>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

export default App;

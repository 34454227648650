import React from 'react';
import ResizeTable from './resizetable';

const data = [];

const generateData = columns => {
  data.splice(0);
  if(columns != null)
  {
    for (let i = 0; i < columns.length; i++) {
      const column = columns[i];
      data.push({ 
        key: i.toString(),
        friendassetname: column.FriendAssetName, 
        friendassetcolumn: column.FriendAssetColumn,
        assetcolumn: column.AssetColumn,
        joblinks: column.JobName });
    }
  }
};

class RelationshipTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      data: data,
      columns: [
        {
          title: 'Friend Asset Name',
          dataIndex: 'friendassetname',
          key: 'friendassetname',
          width: props.tableWidth*0.2,
          sorter: (a, b) => a.friendassetname.localeCompare(b.friendassetname),
        },
        {
          title: 'Friend Asset Column',
          dataIndex: 'friendassetcolumn',
          key: 'friendassetcolumn',
          width: props.tableWidth*0.15,
          sorter: (a, b) => a.friendassetcolumn.localeCompare(b.friendassetcolumn),
        },
        {
          title: 'Asset Column',
          dataIndex: 'assetcolumn',
          key: 'assetcolumn',
          width: props.tableWidth*0.15,
          sorter: (a, b) => a.assetcolumn.localeCompare(b.assetcolumn), 
        },
        {
          title: 'Hot Jobs',
          dataIndex: 'joblinks',
          key: 'joblinks',
          width: props.tableWidth*0.5,
          render: (text, record) => {
            var joblinks = record.joblinks;
            if(joblinks != null && joblinks.length>0)
            {
              var links = JSON.parse(joblinks);
              
              return links.map(link=>{
                  var jobName = link.replace(":::","");
                  var searchtext = this.assetName+","+record.assetcolumn;
                  var httplink = `https://scriptlibrary.azurewebsites.net/CosmosScript?searchText=${searchtext}+jobname:${jobName}&source=main&pageNumber=1&tenant=all&orderByLatest=on`;
                  return (
                      <span><a href={httplink} target={"_blank"}>{jobName}</a><br></br></span>
                    )
              });
            }
          }
        }
      ]
    }
  }

  render() {
    var relations = this.props.relations;
    this.assetName = this.props.assetName;
    var height = window.outerHeight-300>500 ? null : window.outerHeight-300;
    
    generateData(relations);

    return (
      <ResizeTable
        data={this.state.data}
        columns={this.state.columns}
        pageSize={5}
        position={'bottom'}
        scrollx={1000}
        scrolly={height}
        />
    );
  }
}

export default RelationshipTable;
import React from 'react';
import { Descriptions } from 'antd';
import 'antd/dist/antd.css';
import '../index.css';
import JobHistoryTable from './jobhistorytable';
import ResizeTable from '../resizetable';
import LoadingButton from '../button';
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Switch
} from 'antd';
import moment from 'moment';
import { Collapse } from 'antd';

const { Panel } = Collapse;

const config = {
  rules: [{ type: 'object', required: true, message: 'Please select time.' }],
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 4,
    },
  },
};

class RSADropDescriptionTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewcolumns: [],
      previewdata: [],
      jobname: '',
      visibleMap: new Map(),
      visible: false,
      columns: [
        {
          title: 'Column Name',
          dataIndex: 'column_name',
          key: 'column_name',
          width: '25%'
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description'
        }
      ]
    };
  }

  render() {
    var data = this.props.data;
    return (
      <div>
        <ResizeTable name={this.props.assetName} data={data} columns={this.state.columns} pagination={false}
          // pageSize={10}
          // position={'bottom'}
          pageSize={20}
          position={'none'}
        />
      </div>
    );
  }
}

class RSAAdsDropFormST1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      historyData: [],
      submitstatus: '',
      availableDates: []
    };
  }

  componentWillMount() {
    if (this.props.accessToken != null) {
      var userName = this.props.userName;
      var accessToken = this.props.accessToken;
      var assetName = "RSAAdsDrop-Stage0";
      this.GetHistoryQuery(userName, accessToken, assetName);
      this.GetDataAvailability(accessToken);
    }
  }

  GetDataAvailability(accessToken) {
    var path = process.env.REACT_APP_API_URL + "/api/DataAnalysisTools/GetAvailableDates?jobType=2";
    fetch(path, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + accessToken
      }
    }).then(
      res => res.json()
    ).then(response => {
      console.log("GetDataAvailability.response", response)
      var availableDates = []
      if (response != null) {
        for (let i = 0; i < response.length; i++) {
          availableDates[i] = response[i];
        }
      }
      console.log("GetDataAvailability.availableDates", availableDates)
      this.setState({ availableDates: availableDates });
    }).catch((err) => {
      console.log(err);
    })
  }

  GetHistoryQuery(userName, accessToken, assetName) {
    var path = process.env.REACT_APP_API_URL + "/api/AdHocQuery/GetSubmitHistory?userName=" + userName + "&assetName=" + assetName;
    fetch(path, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + accessToken
      }
    }).then(
      res => res.json()
    ).then(response => {
      console.log(response);
      var entity = response.Entity;
      if (entity != null) {
        var historyData = [];
        for (let i = 0; i < entity.length; i++) {
          const job = entity[i];
          historyData[i] = {
            'id': job.ID,
            'user_name': job.UserName,
            'asset_name': job.AssetName,
            'job_id': job.JobId,
            'job_name': job.JobName,
            'running_platform': job.RunningPlatform,
            'job_type': job.JobType,
            'job_type_string': job.JobTypeString,
            'job_status': job.JobStatus,
            'job_status_string': job.JobStatusString,
            'job_url': job.JobUrl,
            'submit_time': moment(job.SubmitTime).format('YYYY-MM-DDTHH:mm:ss'),
            'complete_time': job.CompleteTime,
            'output_path': job.OutputPath,
            'script': job.Script,
            'description': job.Description
            // 'post_processing_result': job.PostProcessingResult
          };
        }
        this.setState({ historyData: historyData });
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  Run = async () => {
    this.setState({ submitstatus: 'Submitting in progress...' });
    var validateFields = ['starttime', 'endtime'];
    if (this.props.assetNames != null) {
      validateFields.push('assetname');
    }
    var validated = false;
    var starttime, endtime;

    this.props.form.validateFields(validateFields, (err, values) => {
      if (!err) {
        starttime = values.starttime;
        endtime = values.endtime;
        validated = true;
      }
    });

    if (validated) {
      var userName = this.props.userName;
      var accessToken = this.props.accessToken;
      var startDate = moment(starttime).format('YYYY-MM-DD');
      var endDate = moment(endtime).format('YYYY-MM-DD');
      var path = process.env.REACT_APP_API_URL
        + `/api/DataAnalysisTools/SubmitRSAAdsDropJob?userName=${userName}&stage=0&startDate=${startDate}&endDate=${endDate}`;
      var req_content = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + accessToken
        }
      }

      await fetch(path, req_content).then(
        res => res.json()
      ).then(response => {
        if (!response.HasErrors) {
          var entity = response.Entity;
          var job = {
            'id': entity.ID,
            'user_name': entity.UserName,
            'asset_name': entity.AssetName,
            'job_id': entity.JobId,
            'job_name': entity.JobName,
            'running_platform': entity.RunningPlatform,
            'job_type': entity.JobType,
            'job_type_string': entity.JobTypeString,
            'job_status': entity.JobStatus,
            'job_status_string': entity.JobStatusString,
            'submit_time': moment(entity.SubmitTime).utc().format('YYYY-MM-DDTHH:mm:ss'),
            'job_url': entity.JobUrl,
            'complete_time': entity.CompleteTime,
            'output_path': entity.OutputPath,
            'script': entity.Script,
            'description': entity.Description
          }
          var job_count = this.state.historyData.unshift(job);

          this.setState({ submitstatus: 'Job is submitted. Please check Query Result for details.' });
        } else {
          this.setState({ submitstatus: response.Errors[0].Code + response.Errors[0].Message });
        }
      }).catch((err) => {
        console.log(err);
        this.setState({ submitstatus: 'Submit failed.' });
      })
    }
  }

  UpdateJobInfo(id) {
    console.log("UpdateJobInfo" + id)
    var path = process.env.REACT_APP_API_URL + "/api/AdHocQuery/Getjobstatus?id=" + id;
    fetch(path, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.props.accessToken
      }
    }).then(
      res => res.json()
    ).then(response => {
      console.log(response);
      var entity = response.Entity;
      if (entity != null) {
        var historyData = this.state.historyData;
        for (let i = 0; i < historyData.length; i++) {
          const job = historyData[i];
          if (job.id === id) {
            job.job_status = entity.JobStatus;
            job.job_status_string = entity.JobStatusString;
            job.job_url = entity.JobUrl;
            this.setState({ historyData: historyData });
            break;
          }
        }
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  ClearSubmitStatus() {
    this.setState({ submitstatus: '' });
  }

  handleReset = () => {
    this.props.form.resetFields();
    this.ClearSubmitStatus();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    var userName = this.props.userName;
    var accessToken = this.props.accessToken;
    var assetName = "RSAAdsDrop-Stage0";
    // console.log("render: " + this.state.lastAvailableDate)
    console.log("render: " + this.state.availableDates)

    const disabledDate = (date) => {
      var dateString = moment(date).format('YYYY-MM-DD')
      return !this.state.availableDates.includes(dateString)
    }

    return (
      <Collapse bordered={false} onChange={this.callback} defaultActiveKey={["0"]}>
        <Panel header="Description" key="0">
          <Descriptions column={1} size={"small"} title="This tool compares the total number of servable RSAs on End Date and Start Date, and finds the top 1000 contributing customers to the drop. Result includes two sections as Summary and Top Contributors.">
            <Descriptions.Item label="Summary">
              <RSADropDescriptionTable showPagination={false} showPaginationBottom={false}
                data={[
                  { column_name: '[StartDate]_ServableCnt', description: '# of servable RSAs at [StartDate]' },
                  { column_name: '[EndDate]_ServableCnt', description: '# of servable RSAs at [EndDate]' },
                  { column_name: 'DropCnt', description: '[EndDate]_ServableCnt - [StartDate]_ServableCnt' },
                  { column_name: '[StartDate]_30DaysRSASpend', description: 'The total spend of servable RSAs on [StartDate] from [StartDate] - 30 to [StartDate]' },
                  { column_name: '[EndDate]_30DaysRSASpend', description: 'The total spend of servable RSAs on [EndDate] from [EndDate] - 30 to [EndDate]' },
                  { column_name: 'RSASpendDrop', description: '[EndDate]_30DaysRSASpend - [StartDate]_30DaysRSASpend' },
                ]}></RSADropDescriptionTable>
            </Descriptions.Item>
            <Descriptions.Item label="Top Contributors">
              <RSADropDescriptionTable
                data={[
                  { column_name: 'CustomerId', description: '' },
                  { column_name: 'CustomerName', description: '' },
                  { column_name: 'CustomerMarket', description: '' },
                  { column_name: 'IsAggregator', description: '' },
                  { column_name: 'DropCnt ', description: 'Servable RSA drop from EndDate to StartDate of  this customer' },
                  { column_name: 'DropContribution', description: 'Servable RSA drop of this customer / total servable RSA drop' },
                  { column_name: 'Causes', description: "The causes of this customer's servable RSA drop" },
                  { column_name: '[StartDate]_ServableCnt', description: '' },
                  { column_name: '[EndDate]_ServableCnt', description: '' },
                  { column_name: 'AbsoluteDropCnt(exclude addition)', description: 'Pure servable RSA drops without considering addition' },
                  { column_name: 'RSASpendDrop', description: '[EndDate]_30DaysRSASpend - [StartDate]_30DaysRSASpend for this customer' },
                  { column_name: 'RSASpendDropContribution', description: 'RSASpendDrop of this customer / total RSASpendDrop' },
                  { column_name: 'AccountSegment', description: '' },
                  { column_name: 'Verticals', description: '' },
                ]}></RSADropDescriptionTable>
            </Descriptions.Item>
          </Descriptions>
        </Panel>

        <Panel header="Query" key="1">
          <p>Please expect 10-15 mins for the job to complete.<br />Please be aware that the source data usually have two days delay.</p>
          <Form onSubmit={this.handleSubmit}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            layout="horizontal"
            size="small">
            <Form.Item name="starttime" label="Start Time" {...config}>
              {
                getFieldDecorator('starttime', { initialValue: moment(this.state.availableDates.slice(-1), 'YYYY-MM-DD').add(-7, 'days') })
                  (
                    <DatePicker showTime={false}
                      format="YYYY-MM-DD"
                      disabledDate={disabledDate}
                    />
                  )
              }
            </Form.Item>
            <Form.Item name="endtime" label="End Time" {...config}>
              {
                getFieldDecorator('endtime', { initialValue: moment(this.state.availableDates.slice(-1), 'YYYY-MM-DD') })
                  (
                    <DatePicker showTime={false}
                      format="YYYY-MM-DD"
                      disabledDate={disabledDate}
                    />
                  )
              }
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <LoadingButton text={"Run"} onClick={this.Run} />
              <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>Clear</Button>
              <div><span style={{ color: '#f5222d' }}>{this.state.submitstatus}</span></div>
            </Form.Item>
          </Form>
        </Panel>
        <Panel header="Query Results" key="2">
          <JobHistoryTable historyData={this.state.historyData}
            assetName={assetName} accessToken={accessToken} userName={userName}
            UpdateJobInfo={(id) => { this.UpdateJobInfo(id) }} />
          <br />
        </Panel>
      </Collapse>
    );
  }
}

export default Form.create({ name: 'generate' })(RSAAdsDropFormST1);
import React from 'react';
import { Drawer, Form, Button, Input, Tooltip } from 'antd';
import { SmileOutlined, FrownOutlined, SmileFilled, FrownFilled} from '@ant-design/icons';


class Feedback extends React.Component {
  state = { visible: false };

  showDrawer = () => {
    this.setState({
      visible: true,
      like:null
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
    this.props.form.resetFields();
  };

  onSubmit = e => {
    if(this.state.like == null)
        return;
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
        this.SaveFeedback(this.state.like, values.feedback)
    });
    this.onClose();
  };

  like = () => {
    this.setState({
        like: true,
      });
  };

  dislike = () => {
    this.setState({
        like: false,
      });
  };

  SaveFeedback(like, feedback)
  {
    var content = {
        "LikeOrNot": like,
        "Content": feedback,
        "UserName": this.props.userName
      };
    var path = process.env.REACT_APP_API_URL+'/api/Feedback';
    fetch(path, {
      method:'POST',
      headers:{
        'Content-Type':'application/json',
        'Access-Control-Allow-Origin':'*',
        'Authorization': 'Bearer ' + this.props.accessToken
      },
      body:JSON.stringify(content)
    }).catch((err)=>{
        console.log(err);
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <>
        <Tooltip title="Feedback"><SmileOutlined style={{ fontSize:20, color: '#fff' }} onClick={this.showDrawer}/></Tooltip>
        <Drawer
          title="Feedback"
          width={400}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <div><span>Thank you for taking the time to give us feedback.</span><br/><br/></div>
          <Form layout="vertical">
            <Form.Item name="likeOrNot" label="Are you satisfied with your experience?" required={true}>
                <div>
                    <span>
                        <Tooltip title="Like">
                            {
                                this.state.like === true ?
                                    <SmileFilled style={{ fontSize:28 }} />
                                    : <SmileOutlined style={{ fontSize:28 }} onClick={this.like} />
                            }
                        </Tooltip>
                        &nbsp;&nbsp;
                    </span>
                    <span>
                        <Tooltip title="DisLike">
                            {
                                this.state.like === false ? 
                                    <FrownFilled style={{ fontSize:28 }} />
                                    : <FrownOutlined style={{ fontSize:28 }} onClick={this.dislike}/>
                            }
                        </Tooltip>
                    </span>
                </div>
            </Form.Item>    
            <Form.Item name="feedback">
                {
                    getFieldDecorator('feedback', {initialValue:"", rules: [{ required: false }]})
                    (
                        <Input.TextArea rows={4} placeholder="Tell us about your experience..."/>
                    )
                }
            </Form.Item> 
          </Form>
          <div
            style={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={this.onSubmit} type="primary">
              Submit
            </Button>
          </div>
        </Drawer>
      </>
    );
                }
}

export default Form.create({ name: 'feedback' })(Feedback);
// export default Feedback;
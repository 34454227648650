import React from 'react';
import { Descriptions } from 'antd';
import 'antd/dist/antd.css';
import '../index.css';
import JobHistoryTable from './jobhistorytable';
import ResizeTable from '../resizetable';
import LoadingButton from '../button';
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Switch
} from 'antd';
import moment from 'moment';
import { Collapse } from 'antd';

const { Panel } = Collapse;

const config = {
  rules: [{ type: 'object', required: true, message: 'Please select time.' }],
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 4,
    },
  },
};

class DebuggingToolDescriptionTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewcolumns: [],
      previewdata: [],
      jobname: '',
      visibleMap: new Map(),
      visible: false,
      columns: [
        {
          title: 'Column Name',
          dataIndex: 'column_name',
          key: 'column_name',
          width: '25%'
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description'
        }
      ]
    };
  }

  render() {
    var data = this.props.data;
    return (
      <div>
        <ResizeTable name={this.props.assetName} data={data} columns={this.state.columns} pagination={false}
          pageSize={20}
          position={'none'}
        />
      </div>
    );
  }
}

class DebuggingToolFormST1 extends React.Component {
  constructor(props) {
    super(props);
    this.regionRef = React.createRef();
    this.mkGroupRef = React.createRef();
    this.state = {
      historyData: [],
      submitstatus: '',
      availableDates: []
    };
  }

  componentWillMount() {
    if (this.props.accessToken != null) {
      var userName = this.props.userName;
      var accessToken = this.props.accessToken;
      var assetName = "AdsDropDebugging-Stage0";
      this.GetHistoryQuery(userName, accessToken, assetName);
      this.GetDataAvailability(accessToken);
      this.GetOptions(accessToken);
    }
    this.setState({ enableRegion: true });
    this.setState({ enableMKGroup: true });
  }

  GetDataAvailability(accessToken) {
    var path = process.env.REACT_APP_API_URL + "/api/DataAnalysisTools/GetAvailableDates?jobType=3";
    fetch(path, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + accessToken
      }
    }).then(
      res => res.json()
    ).then(response => {
      console.log("GetDataAvailability.response", response)
      var availableDates = []
      if (response != null) {
        for (let i = 0; i < response.length; i++) {
          availableDates[i] = response[i];
        }
      }
      console.log("GetDataAvailability.availableDates", availableDates)
      this.setState({ availableDates: availableDates });
    }).catch((err) => {
      console.log(err);
    })
  }

  GetHistoryQuery(userName, accessToken, assetName) {
    var path = process.env.REACT_APP_API_URL + "/api/AdHocQuery/GetSubmitHistory?userName=" + userName + "&assetName=" + assetName;
    fetch(path, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + accessToken
      }
    }).then(
      res => res.json()
    ).then(response => {
      console.log(response);
      var entity = response.Entity;
      if (entity != null) {
        var historyData = [];
        for (let i = 0; i < entity.length; i++) {
          const job = entity[i];
          historyData[i] = {
            'id': job.ID,
            'user_name': job.UserName,
            'asset_name': job.AssetName,
            'job_id': job.JobId,
            'job_name': job.JobName,
            'running_platform': job.RunningPlatform,
            'job_type': job.JobType,
            'job_type_string': job.JobTypeString,
            'job_status': job.JobStatus,
            'job_status_string': job.JobStatusString,
            'job_url': job.JobUrl,
            'submit_time': moment(job.SubmitTime).format('YYYY-MM-DDTHH:mm:ss'),
            'complete_time': job.CompleteTime,
            'output_path': job.OutputPath,
            'script': job.Script,
            'description': job.Description
          };
        }
        this.setState({ historyData: historyData });
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  GetOptions(accessToken) {
    var path = process.env.REACT_APP_API_URL + "/api/DataAnalysisTools/GetOptions";
    fetch(path, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + accessToken
      }
    }).then(
      res => res.json()
    ).then(response => {
      console.log(response);
      this.setState({ entityTypes: response["EntityType"] });
      this.setState({ entityStatuses: response["EntityStatus"] });
      this.setState({ regions: response["Region"] });
      this.setState({ marketGroups: response["MarketGroup"] });
      this.setState({ accountSegments: response["AccountSegment"] });
      this.setState({ aggregators: response["Aggregator"] });
      this.setState({ operationSources: response["OperationSource"] });
      this.setState({ campaignTypes: response["CampaignType"] });
      this.setState({ adTypes: response["AdType"] });
    }).catch((err) => {
      console.log(err);
    })
  }

  Run = async () => {
    this.setState({ submitstatus: 'Submitting in progress...' });
    var validateFields = ['starttime', 'endtime', 'entityType', 'entityStatus', 'region', 'marketGroup', 'adType', 'accountSegment', 'aggregator', 'operationSource', 'campaignType'];
    if (this.props.assetNames != null) {
      validateFields.push('assetname');
    }
    var validated = false;
    var starttime, endtime, entityType, entityStatus, region, marketGroup, adType, accountSegment, aggregator, operationSource, campaignType;

    this.props.form.validateFields(validateFields, (err, values) => {
      console.log("[DebuggingToolFormST1::Run]", values)
      if (!err) {
        starttime = values.starttime;
        endtime = values.endtime;
        entityType = values.entityType;
        entityStatus = values.entityStatus;
        region = values.region;
        marketGroup = values.marketGroup;
        adType = values.adType;
        accountSegment = values.accountSegment;
        aggregator = values.aggregator;
        operationSource = values.operationSource;
        campaignType = values.campaignType;
        validated = true;
      }
    });

    if (validated) {
      var userName = this.props.userName;
      var accessToken = this.props.accessToken;
      var startDate = moment(starttime).format('YYYY-MM-DD');
      var endDate = moment(endtime).format('YYYY-MM-DD');
      var path = process.env.REACT_APP_API_URL
        + `/api/DataAnalysisTools/SubmitAdsDropDebuggingJob?userName=${userName}&startDate=${startDate}&endDate=${endDate}`
        + `&entityType=${entityType}&entityStatus=${entityStatus}&region=${region}&marketGroup=${marketGroup}&adType=${adType}`
        + `&accountSegment=${accountSegment}&aggregator=${aggregator}&operationSource=${operationSource}&campaignType=${campaignType}`;
      var req_content = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + accessToken
        }
      }

      await fetch(path, req_content).then(
        res => res.json()
      ).then(response => {
        if (!response.HasErrors) {
          var entity = response.Entity;
          var job = {
            'id': entity.ID,
            'user_name': entity.UserName,
            'asset_name': entity.AssetName,
            'job_id': entity.JobId,
            'job_name': entity.JobName,
            'running_platform': entity.RunningPlatform,
            'job_type': entity.JobType,
            'job_type_string': entity.JobTypeString,
            'job_status': entity.JobStatus,
            'job_status_string': entity.JobStatusString,
            'submit_time': moment(entity.SubmitTime).utc().format('YYYY-MM-DDTHH:mm:ss'),
            'job_url': entity.JobUrl,
            'complete_time': entity.CompleteTime,
            'output_path': entity.OutputPath,
            'script': entity.Script,
            'description': entity.Description
          }
          var job_count = this.state.historyData.unshift(job);

          this.setState({ submitstatus: 'Job is submitted. Please check Query Result for details.' });
        } else {
          this.setState({ submitstatus: response.Errors[0].Code + response.Errors[0].Message });
        }
      }).catch((err) => {
        console.log(err);
        this.setState({ submitstatus: 'Submit failed.' });
      })
    }
  }

  UpdateJobInfo(id) {
    console.log("UpdateJobInfo" + id)
    var path = process.env.REACT_APP_API_URL + "/api/AdHocQuery/Getjobstatus?id=" + id;
    fetch(path, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.props.accessToken
      }
    }).then(
      res => res.json()
    ).then(response => {
      console.log(response);
      var entity = response.Entity;
      if (entity != null) {
        var historyData = this.state.historyData;
        for (let i = 0; i < historyData.length; i++) {
          const job = historyData[i];
          if (job.id === id) {
            job.job_status = entity.JobStatus;
            job.job_status_string = entity.JobStatusString;
            job.job_url = entity.JobUrl;
            this.setState({ historyData: historyData });
            break;
          }
        }
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  ClearSubmitStatus() {
    this.setState({ submitstatus: '' });
  }

  handleReset = () => {
    this.props.form.resetFields();
    this.ClearSubmitStatus();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    // const ref = useRef(null);
    // const node = this.myRef.current;

    var userName = this.props.userName;
    var accessToken = this.props.accessToken;
    var assetName = "AdsDropDebugging-Stage0";
    console.log("render: " + this.state.availableDates)
    console.log("render: " + this.state.entityTypes)

    const handleEntityChange = (value) => {
      var campaignType = document.getElementsByName('campaignType')[0];
      var adType = document.getElementsByName('adType')[0];
      if (value === 1) {
        campaignType.style.display = 'inline';
        adType.style.display = 'none';
      } else if (value === 2) {
        adType.style.display = 'inline';
        campaignType.style.display = 'none';
      } else {
        adType.style.display = 'none';
        campaignType.style.display = 'none';
      }
    };

    const handleRegionChange = (value) => {
      if (value == 0) { // Region = All
        this.setState({ enableMKGroup: true });
      } else {
        this.setState({ enableMKGroup: false });
      }
    }

    const handleMKChange = (value) => {
      if (value == 0) { // MarketGroup = All
        this.setState({ enableRegion: true });
      } else {
        this.setState({ enableRegion: false });
      }
    }

    const disabledDate = (date) => {
      var dateString = moment(date).format('YYYY-MM-DD')
      return !this.state.availableDates.includes(dateString)
    }

    return (
      <Collapse bordered={false} onChange={this.callback} defaultActiveKey={["0"]}>
        <Panel header="Description" key="0">
          <Descriptions column={1} size={"small"} title="This tool compares the number of accounts/campaigns/ads on End Date against Start Date, and finds the top 1000 customers (and accounts) contributing to the count drop. Result includes three sections as Summary, Top Customers and Top Accounts.">
            <Descriptions.Item label="Summary">
              <DebuggingToolDescriptionTable showPagination={false} showPaginationBottom={false}
                data={[
                  { column_name: '[StartDate]_Cnt', description: '# of entities at [StartDate]' },
                  { column_name: '[EndDate]_Cnt', description: '# of entities at [EndDate]' },
                  { column_name: 'DropCnt', description: '[EndDate]_Cnt - [StartDate]_Cnt' },
                  { column_name: 'DropRatio', description: '([EndDate]_Cnt - [StartDate]_Cnt)/ [StartDate]_Cnt' },
                ]}></DebuggingToolDescriptionTable>
            </Descriptions.Item>
            <Descriptions.Item label="Top 1000 Customers by Entity Drop (for Entity Type Account, Campaign, Ad)">
              <DebuggingToolDescriptionTable
                data={[
                  { column_name: 'CustomerId', description: '' },
                  { column_name: 'CustomerName', description: '' },
                  { column_name: 'DropCnt ', description: '[EndDate]_Cnt-[StartDate]_Cnt' },
                  { column_name: '[StartDate]_Cnt', description: '' },
                  { column_name: '[EndDate]_Cnt', description: '' },
                  { column_name: 'AbsoluteDropCnt(exclude addition)', description: 'Pure entity drops without considering addition' },
                  { column_name: 'DropContribution', description: 'AbsoluteDropCnt/TotalDropCnt' },
                  { column_name: 'Causes', description: 'The causes of this customers entity drop' },
                  { column_name: '[StartDate]_CustomerLast30DaysSpend', description: '' },
                  { column_name: '[EndDate]_CustomerLast30DaysSpend', description: '' },
                  { column_name: 'AccountSegment', description: '' },
                  { column_name: 'Verticals', description: '' },
                  { column_name: 'MarketGroup', description: '' },
                  { column_name: 'Region', description: '' },
                  { column_name: 'IsAggregator', description: '' }
                ]}></DebuggingToolDescriptionTable>
            </Descriptions.Item>
            <Descriptions.Item label="Top 1000 Accounts by Entity Drop (for Entity Type Campaign, Ad)">
              <DebuggingToolDescriptionTable
                data={[
                  { column_name: 'CustomerId', description: '' },
                  { column_name: 'CustomerName', description: '' },
                  { column_name: 'AccountId', description: '' },
                  { column_name: 'AccountName', description: '' },
                  { column_name: 'DropCnt ', description: '[EndDate]_Cnt-[StartDate]_Cnt' },
                  { column_name: '[StartDate]_Cnt', description: '' },
                  { column_name: '[EndDate]_Cnt', description: '' },
                  { column_name: 'AbsoluteDropCnt(exclude addition)', description: 'Pure entity drops without considering addition' },
                  { column_name: 'DropContribution', description: 'AbsoluteDropCnt/TotalDropCnt' },
                  { column_name: 'Causes', description: 'The causes of this customers entity drop' },
                  { column_name: '[StartDate]_CustomerLast30DaysSpend', description: '' },
                  { column_name: '[EndDate]_CustomerLast30DaysSpend', description: '' },
                  { column_name: 'AccountSegment', description: '' },
                  { column_name: 'Verticals', description: '' },
                  { column_name: 'MarketGroup', description: '' },
                  { column_name: 'Region', description: '' },
                  { column_name: 'IsAggregator', description: '' }
                ]}></DebuggingToolDescriptionTable>
            </Descriptions.Item>
          </Descriptions>
        </Panel>

        <Panel header="Query" key="1">
          <p>Please expect 10-40 mins for the job to complete.<br />Please be aware that the source data usually have two days delay.</p>
          <Form onSubmit={this.handleSubmit}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            layout="horizontal"
            size="small">
            <Form.Item name="starttime" label="Start Time" {...config}>
              {
                getFieldDecorator('starttime', { initialValue: moment(this.state.availableDates.slice(-1), 'YYYY-MM-DD').add(-7, 'days') })
                  (
                    <DatePicker showTime={false}
                      format="YYYY-MM-DD"
                      disabledDate={disabledDate}
                    />
                  )
              }
            </Form.Item>
            <Form.Item name="endtime" label="End Time" {...config}>
              {
                getFieldDecorator('endtime', { initialValue: moment(this.state.availableDates.slice(-1), 'YYYY-MM-DD') })
                  (
                    <DatePicker showTime={false}
                      format="YYYY-MM-DD"
                      disabledDate={disabledDate} />
                  )
              }
            </Form.Item>
            <Form.Item name="entityType" label="Entity type">
              {
                this.state.entityTypes != null ?
                  getFieldDecorator('entityType', { initialValue: 2 })
                    (
                      <Select onChange={handleEntityChange} >
                        {
                          this.state.entityTypes.map(keyValue => {
                            return <Select.Option value={keyValue.Item2} >{keyValue.Item1}</Select.Option>
                          })
                        }
                      </Select>
                    )
                  : null
              }
            </Form.Item>
            <Form.Item name="entityStatus" label="Entity Status">
              {
                this.state.entityStatuses != null ?
                  getFieldDecorator('entityStatus', { initialValue: 3 })
                    (
                      <Select >
                        {
                          this.state.entityStatuses.map(keyValue => {
                            return <Select.Option value={keyValue.Item2} >{keyValue.Item1}</Select.Option>
                          })
                        }
                      </Select>
                    )
                  : null
              }
            </Form.Item>
            <Form.Item name="region" label="Region">
              {
                this.state.regions != null ?
                  getFieldDecorator('region', { initialValue: 0 })
                    (
                      <Select onChange={handleRegionChange} ref={this.regionRef} disabled={!this.state.enableRegion}>
                        {
                          this.state.regions.map(keyValue => {
                            return <Select.Option value={keyValue.Item2} >{keyValue.Item1}</Select.Option>
                          })
                        }
                      </Select>
                    )
                  : null
              }
            </Form.Item>
            <Form.Item name="marketGroup" label="Market Group">
              {
                this.state.marketGroups != null ?
                  getFieldDecorator('marketGroup', { initialValue: 0 })
                    (
                      <Select onChange={handleMKChange} ref={this.mkGroupRef} disabled={!this.state.enableMKGroup}>
                        {
                          this.state.marketGroups.map(keyValue => {
                            return <Select.Option value={keyValue.Item2} >{keyValue.Item1}</Select.Option>
                          })
                        }
                      </Select>
                    )
                  : null
              }
            </Form.Item>
            <Form.Item name="accountSegment" label="Account Segment">
              {
                this.state.accountSegments != null ?
                  getFieldDecorator('accountSegment', { initialValue: 0 })
                    (
                      <Select >
                        {
                          this.state.accountSegments.map(keyValue => {
                            return <Select.Option value={keyValue.Item2} >{keyValue.Item1}</Select.Option>
                          })
                        }
                      </Select>
                    )
                  : null
              }
            </Form.Item>
            <Form.Item name="aggregator" label="Aggregator">
              {
                this.state.aggregators != null ?
                  getFieldDecorator('aggregator', { initialValue: 0 })
                    (
                      <Select >
                        {
                          this.state.aggregators.map(keyValue => {
                            return <Select.Option value={keyValue.Item2} >{keyValue.Item1}</Select.Option>
                          })
                        }
                      </Select>
                    )
                  : null
              }
            </Form.Item>
            <Form.Item name="operationSource" label="Operation Source">
              {
                this.state.operationSources != null ?
                  getFieldDecorator('operationSource', { initialValue: 0 })
                    (
                      <Select >
                        {
                          this.state.operationSources.map(keyValue => {
                            return <Select.Option value={keyValue.Item2} >{keyValue.Item1}</Select.Option>
                          })
                        }
                      </Select>
                    )
                  : null
              }
            </Form.Item>
            <Form.Item name="campaignType" label="Campaign Type" style={{ display: 'none' }}>
              {
                this.state.campaignTypes != null ?
                  getFieldDecorator('campaignType', { initialValue: 0 })
                    (
                      <Select >
                        {
                          this.state.campaignTypes.map(keyValue => {
                            return <Select.Option value={keyValue.Item2} >{keyValue.Item1}</Select.Option>
                          })
                        }
                      </Select>
                    )
                  : null
              }
            </Form.Item>
            <Form.Item name="adType" label="Ad Type" style={{ display: 'inline' }}>
              {
                this.state.adTypes != null ?
                  getFieldDecorator('adType', { initialValue: 0 })
                    (
                      <Select >
                        {
                          this.state.adTypes.map(keyValue => {
                            return <Select.Option value={keyValue.Item2} >{keyValue.Item1}</Select.Option>
                          })
                        }
                      </Select>
                    )
                  : null
              }
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <LoadingButton text={"Run"} onClick={this.Run} />
              <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>Clear</Button>
              <div><span style={{ color: '#f5222d' }}>{this.state.submitstatus}</span></div>
            </Form.Item>
          </Form>
        </Panel>
        <Panel header="Query Results" key="2">
          <JobHistoryTable historyData={this.state.historyData}
            assetName={assetName} accessToken={accessToken} userName={userName}
            UpdateJobInfo={(id) => { this.UpdateJobInfo(id) }} />
          <br />
        </Panel>
      </Collapse>
    );
  }
}

export default Form.create({ name: 'generate' })(DebuggingToolFormST1);
import React from "react";
import {
  DiagramEngine,
  DiagramModel,
  DefaultNodeModel,
  DefaultPortModel,
  DiagramWidget,
  DefaultLinkModel
} from "storm-react-diagrams";

require("storm-react-diagrams/dist/style.min.css");

class Workspace extends React.Component {

  renderDiagram = (relations, words, assetName) => {
    if(relations.length === 0)
      return;

    //1) setup the diagram engine
    var engine = new DiagramEngine();
    engine.installDefaultFactories();

    //2) setup the diagram model
    var model = new DiagramModel();

    //3) create nodes and links
    var node = new DefaultNodeModel(assetName, "rgb(0,192,255)");
    model.addNode(node);

    var dict = {};
    var index = 0;
    for (let i = 0; i < relations.length; i++) {
      if(Object.keys(dict).length >= 20)
        break;

      const relation = relations[i];
      var fnode = dict[relation.FriendAssetName];
      if(fnode == null)
      {
        fnode = new DefaultNodeModel(relation.FriendAssetName, "rgb(192,255,0)");
        dict[relation.FriendAssetName]=fnode;
        index++;
      }
      
      var fport = new DefaultPortModel(true, relation.FriendAssetColumn);
      fnode.addPort(fport);
      var port = node.getPort(relation.AssetColumn);
      if(port == null)
      {
        port = new DefaultPortModel(false, relation.AssetColumn);
        node.addPort(port);
      }
      
      var link = new DefaultLinkModel();
      link.setColor("rgb(169, 172, 182)");
      link.setSourcePort(fport);
      link.setTargetPort(port);

      model.addNode(fnode);
      model.addLink(link);
    }

    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      var newport = node.getPort(word.text);
      if(newport == null)
      {
        newport = new DefaultPortModel(false, word.text);
        node.addPort(newport); 
      }
    }

    //Set position
    var y = 0;
    // var twoside = Object.keys(dict).length > 10;
    // if(twoside)
    // {
    //   var index = 0;
    //   var mid = Object.keys(dict).length/2;
    //   for(var key in dict)
    //   {
    //     var fnode = dict[key];
    //     var len = fnode.getInPorts().length;
    //     var x = index >= mid ? 800 : 0;
    //     fnode.setPosition(x, y);
    //     y = index === mid-1 ? 0 : y + 30 + len*25;
    //     index++;
    //   }
    //   y = (y-(30+node.getOutPorts().length*25))/2;
    //   node.setPosition(400, y > 0 ? y : 0);
    // }
    // else
    // {
      for(var key in dict)
      {
        var fnode = dict[key];
        var len = fnode.getInPorts().length;
        fnode.setPosition(400, y);
        y = y + 30 + len*25;
      }
      y = (y-(30+node.getOutPorts().length*25))/2;
      node.setPosition(50, y > 0 ? y : 0);
    // }

    //5) load model into engine
    engine.setDiagramModel(model);

    //6) render the diagram!
    return (
      <DiagramWidget
        className="srd-demo-canvas"
        diagramEngine={engine}
        allowLooseLinks={false}
      />
    );
  };

  render() {
    var relations = this.props.relations;
    var words = this.props.words;
    var assetName = this.props.assetName;

    return (
      <div>
        <div className="workspace">{this.renderDiagram(relations, words, assetName)}</div>
      </div>
    );
  }
}

export default Workspace;

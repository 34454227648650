import React from 'react';
import { Resizable } from 'react-resizable';

const ResizeableTitle = props => {
    const { onResize, width, ...restProps } = props;
  
    if (!width) {
      return <th {...restProps} />;
    }
  
    return (
      <Resizable
        width={width}
        height={0}
        handle={resizeHandle => (
          <span
            className={`react-resizable-handle react-resizable-handle-${resizeHandle}`}
            onClick={e => {
              e.stopPropagation();
            }}
          />
        )}
        onResize={onResize}
        draggableOpts={{ enableUserSelectHack: false }}
      >
        <th {...restProps} />
      </Resizable>
    );
  };

  export default ResizeableTitle; 


  window.ReplaceColor = function(text, searchValue) 
  {
    var result = (<span></span>);
    var paras = text.split('\r\n');
    for(var i=0; i<paras.length; i++)
    {
      var para = paras[i];
      var index = para.indexOf(":");
      if(index !== -1)
      {
        result =  (<span>{result}<span style={{ fontWeight:'bold' }}>{para.substr(0, index+1)}</span></span>);
      }
      var afterStr = para.substr(index+1);
      index = afterStr.toLowerCase().indexOf(searchValue.toLowerCase());
      while(index > -1)
      {
        var beforeStr = afterStr.substr(0, index);
        var currentValue = afterStr.substr(index, searchValue.length);
        afterStr = afterStr.substr(index + searchValue.length);
        result =  (<span>{result}<span>{beforeStr}</span><span style={{ backgroundColor: 'yellow', fontWeight:'bold' }}>{currentValue}</span></span>);
        index = afterStr.toLowerCase().indexOf(searchValue.toLowerCase());
      } 
      result =  (<span>{result}<span>{afterStr}</span>{i !== paras.length-1 ? <br/> : null}</span>) ;
    }
    
    return result;
  }
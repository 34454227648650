import React from 'react';
import { Button } from 'antd';


class LoadingButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            buttonText: props.text,
            isDisabled: false,
            onClick: props.onClick,
            style: props.style
        };
    }
    onClickBtn = async () => {
        this.setState({ loading: true });
        this.setState({ isDisabled: true });
        await this.state.onClick();
        this.setState({ loading: false });
        this.setState({ isDisabled: false });
    };
    render() {
        return (
            <Button
                size='default'
                id='btnTesting'
                style={this.state.style}
                onClick={this.onClickBtn}
                loading={this.state.loading}
                disabled={this.state.isDisabled}
            >
                {this.state.buttonText}
            </Button>

        );
    }
}

export default LoadingButton;
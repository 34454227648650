import React from 'react';
import 'antd/dist/antd.css';
import '../index.css';
import LoadingButton from '../button';
import {
  Form,
  Icon,
  Input,
  Button,
  Select,
  DatePicker,
} from 'antd';
import moment from 'moment';

const config = {
  rules: [{ type: 'object', required: true, message: 'Please select time.' }],
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 4,
    },
  },
};

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

function disabledDateTime() {
  return {
    disabledMinutes: () => range(1, 60),
    disabledSeconds: () => range(1, 60),
  };
};

let id = 0;

class C2SForm extends React.Component {
  constructor(props) {
    super(props);
  }

  Run = async () => {
    var validateValues = null;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // if (values.starttime != null && values.endtime != null && values.starttime >= values.endtime) {
        //   window.alert("End time should be late than start time.");
        // }
        // else {
        const { keys, querycolumns, queryoperators, queryvalues } = values;
        var queryConditions = [];
        for (var i = 0; i < keys.length; i++) {
          var idx = keys[i];
          var queryCondition = {};
          queryCondition["Column"] = querycolumns[idx];
          queryCondition["Operator"] = queryoperators[idx];
          var queryValues = queryvalues[idx].split(",");
          // If queryValues is null or empty then it should not be added into condition list.
          if (queryValues != null && queryValues.length > 0) {
            queryValues.forEach(function(v, index) {
              this[index] = v.trim();
            }, queryValues);
            queryCondition["Values"] = queryValues;
            queryConditions.push(queryCondition);
          }
        }
        var queryJSON = JSON.stringify(queryConditions);
        validateValues = values;
        validateValues.queryconditions = queryJSON
        // }
      }
    });
    if (validateValues != null) {
      var userName = this.props.userName;
      var assetName = this.props.assetNames == null ?
        this.props.assetName : this.props.prefix + "(" + validateValues.assetname + ")";
      var accessToken = this.props.accessToken;
      // adhocquery.js: SubmitC2SQuery()
      await this.props.SubmitC2SQuery(
        userName,
        assetName,
        validateValues.starttime,
        validateValues.endtime,
        validateValues.viewtype,
        validateValues.queryconditions,
        accessToken
      );
    }
  }

  handleReset = () => {
    this.props.form.resetFields();
    this.props.ClearSubmitStatus();
  };

  remove = k => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    if (keys.length === 0) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter(key => key !== k),
    });
  };

  add = () => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(id++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys,
    });
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    var today = new Date((new Date()).valueOf() - 3 * 1000 * 60 * 60 * 24);
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var currentshortname = this.props.assetName.replace(this.props.prefix + "(", "").replace(")", "");

    var conditionalColumns = this.props.conditionalColumns;
    console.log("[c2sform::render] conditionalColumns", conditionalColumns);

    const handleCountryChange = (value) => { // snapshot/delta
      var starttime = document.getElementsByName('starttime')[0];
      if (value === 'snapshot') {
        starttime.style.display = 'none';
      } else {
        starttime.style.display = 'inline';
      }
      if (this.props.prefix != null && this.props.prefix === "C2SView") {
        var btnaddfiled = document.getElementsByName('btnaddfiled')[0];
        // if (value === 'snapshot') {
           btnaddfiled.style.display = 'inline';
        // } else {
        //   btnaddfiled.style.display = 'none';
        // }
        var conditions = document.getElementsByName('queryconditions');
        if (conditions != null && conditions.length > 0) {
          // if (value === 'snapshot') {
            conditions.forEach(element => {
              element.style.display = 'inline';
            });
          // } else {
          //   conditions.forEach(element => {
          //     element.style.display = 'none';
          //   });
          // }
        }
      }
    };

    const handleAssetChange = (value) => {
      // C2SView(Account)
      var fullName = this.props.prefix + "(" + value + ")";
      this.props.GetConditionalColumns("SubstrateView", fullName, this.props.accessToken)
    };

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 },
      },
    };

    getFieldDecorator('keys', { initialValue: [] });
    const keys = getFieldValue('keys');
    const formItems = keys.map((k, index) => (
      <Form.Item{
        ...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
        name="queryconditions"
        style={{ display: 'inline' }}
        label={index === 0 ? 'Query conditions' : ''}
        required={false}
        key={k}
      >
        {getFieldDecorator(`querycolumns[${k}]`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              whitespace: true,
              message: "Please select query column or delete this field.",
            },
          ],
        })(
          <Select style={{ width: '32%' }} showSearch>
            {conditionalColumns.map(keyValue => {
              return <Select.Option value={keyValue.Key}>{keyValue.Key}</Select.Option>;
            })}
          </Select>
        )}
        {getFieldDecorator(`queryoperators[${k}]`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              whitespace: true,
              message: "Please select operator or delete this field.",
            },
          ],
        })(
          <Select style={{ width: '8%' }} showSearch>
            <Select.Option value='EQ'>=</Select.Option>
            <Select.Option value='IN'>IN</Select.Option>
            <Select.Option value='GT'>&gt;</Select.Option>
            <Select.Option value='LT'>&lt;</Select.Option>
            <Select.Option value='GE'>&ge;</Select.Option>
            <Select.Option value='LE'>&le;</Select.Option>
          </Select>
        )}
        {getFieldDecorator(`queryvalues[${k}]`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              whitespace: true,
              message: "Please input query values or delete this field.",
            },
          ],
        })(
          <Input placeholder="" style={{ width: '50%' }} />
        )}
        &nbsp;
        {
          <Icon
            className="dynamic-delete-button"
            type="minus-circle-o"
            onClick={() => this.remove(k)}
          />
        }
      </Form.Item>
    ));

    return (
      <Form onSubmit={this.handleSubmit}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        size="small">
        <Form.Item name="assetname" label="Asset Name" >
          {
            this.props.assetNames != null ?
              getFieldDecorator('assetname', { rules: [{ required: true }] })
                (
                  <Select showSearch onChange={handleAssetChange}>
                    {
                      this.props.assetNames.map(assetName => {
                        var shortName = assetName.replace(this.props.prefix + "(", "").replace(")", "");
                        return <Select.Option value={shortName} >{shortName}</Select.Option>
                      })
                    }
                  </Select>
                )
              :
              <div><span>{currentshortname}</span></div>
          }
        </Form.Item>
        <Form.Item name="viewtype" label="View Type" >
          {
            getFieldDecorator('viewtype', { initialValue: "snapshot", rules: [{ required: true }] })
              (
                <Select onChange={handleCountryChange}>
                  <Select.Option value="snapshot">Snapshot</Select.Option>
                  <Select.Option value="delta">Delta</Select.Option>
                </Select>
              )
          }
        </Form.Item>
        <Form.Item name="starttime" label="Start Time" {...config} style={{ display: 'none' }}>
          {
            getFieldDecorator('starttime', { initialValue: moment(date, 'YYYY-MM-DD').add(-7, 'days') })
              (
                <DatePicker showTime={{ hideDisabledOptions: true }}
                  format="YYYY-MM-DD HH:00:00"
                  disabledTime={disabledDateTime}
                  defaultValue={moment(date, 'YYYY-MM-DD').add(-7, 'days')} />
              )
          }
        </Form.Item>
        <Form.Item name="endtime" label="End Time" {...config}>
          {
            getFieldDecorator('endtime', { initialValue: moment(date, 'YYYY-MM-DD') })
              (
                <DatePicker showTime={{ hideDisabledOptions: true }}
                  format="YYYY-MM-DD HH:00:00"
                  disabledTime={disabledDateTime}
                  defaultValue={moment(date, 'YYYY-MM-DD')} />
              )
          }
        </Form.Item>
        {formItems}
        <Form.Item {...formItemLayoutWithOutLabel} name="btnaddfiled" style={{ display: 'inline' }}>
          <Button type="dashed" onClick={this.add} style={{ width: '60%' }}>
            <Icon type="plus" /> Add query condition field
          </Button>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <div><span>The maximum number of return is limited to 10000.</span></div>
          <LoadingButton text={"Run"} onClick={this.Run} />
          <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>Clear</Button>
          <div><span style={{ color: '#f5222d' }}>{this.props.submitstatus}</span></div>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: 'generate' })(C2SForm);
import React from 'react';
import { Descriptions, Input } from 'antd';
import { Rate } from 'antd';
import EditableFormTable from './table';
// import {EditOutlined} from '@ant-design/icons';

class Detail extends React.Component {
  state = {
    size: 'small',
    editDescription: false
  };

  onClick = e => {
    console.log('size checked', e.target.value);
    this.setState({editDescription: true});
  };

  render() {
      var detail = this.props.detail;
      var columns = this.props.detail.columns;
      var parameters = this.props.detail.parameters;
      var hasColumns = columns != null ;
      var hasParameters = parameters != null ;
      var accessToken = this.props.accessToken;
      var userName = this.props.userName;
      var rate = this.props.detail.rate;
      var hasRate = rate != null;
      var startTime = detail.startTime;
      var endTime = detail.endTime == null || detail.endTime.startsWith("9999-") ? null : detail.endTime;
      var fidelity = detail.name.startsWith("C2CMasterView.view") ? detail.fidelity : null;
      var tableWidth = window.innerWidth - 400;
      var editDescription = this.state.editDescription;
      var searchValue = this.props.searchValue;

    return (
      <div >
        
        <br />
        <Descriptions title={searchValue !== null ? window.ReplaceColor(detail.name, searchValue) : detail.name} size={this.state.size} column={1} >
        {
            hasRate ? 
              <Descriptions.Item label="Popularity"><Rate value={rate}></Rate></Descriptions.Item>
              : null
          }
          <Descriptions.Item label="Asset Type" >{detail.type}</Descriptions.Item>
          {
            editDescription ? 
              <Descriptions.Item label="Description" >
                <Input.TextArea rows={2} cols={16} value={detail.description}/>
              </Descriptions.Item>
            : 
              <Descriptions.Item label="Description" >{detail.description}</Descriptions.Item>
          }
          <Descriptions.Item label="Primary Keys" >{searchValue !== null && detail.primaryKeys !== null ? window.ReplaceColor(detail.primaryKeys, searchValue) : detail.primaryKeys}</Descriptions.Item>
          <Descriptions.Item label="Tags">{searchValue !== null && detail.tags != null ? window.ReplaceColor(detail.tags, searchValue) : detail.tags}</Descriptions.Item>
          {
            startTime ? 
              <Descriptions.Item label="Start Time">{detail.startTime}</Descriptions.Item>
              : null
          }
          {
            endTime ? 
              <Descriptions.Item label="End Time">{endTime}</Descriptions.Item>
              : null
          }
          {
            fidelity != null ?
              <Descriptions.Item label="Fidelity">{fidelity}</Descriptions.Item>
              : null
          }
          <Descriptions.Item label="Owner">{detail.owner}</Descriptions.Item>
          {
            hasColumns ? 
                <Descriptions.Item label="Columns">
                    <EditableFormTable 
                    columns={columns}
                    userName={userName} 
                    assetName={detail.name} 
                    assetType={detail.type}
                    isParameters={false}
                    accessToken={accessToken}
                    tableWidth={tableWidth}
                    searchValue={searchValue} />
                  </Descriptions.Item> 
              : null
          }
          {
            hasParameters ? 
                <Descriptions.Item label="Parameters">
                  <EditableFormTable 
                  columns={parameters}
                  userName={userName}
                  assetName={detail.name} 
                  assetType={detail.type}
                  isParameters={true}
                  accessToken={accessToken}
                  tableWidth={tableWidth}
                  searchValue={searchValue} />
                </Descriptions.Item> 
              : null
          }
        </Descriptions>

        <br />
        <br />
      </div>
    );
  }
}

export default Detail;
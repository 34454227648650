import React from 'react';
import 'antd/dist/antd.css';
import '../index.css';
import LoadingButton from '../button';
import {
  Form,
  Icon,
  Input,
  Button,
  Select,
  DatePicker,
  Switch
} from 'antd';
import moment from 'moment';

const config = {
  rules: [{ type: 'object', required: true, message: 'Please select time!' }],
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 4,
    },
  },
};

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

function disabledDateTime() {
  return {
    disabledMinutes: () => range(1, 60),
    disabledSeconds: () => range(1, 60),
  };
}

let id = 0;

class GenerateULDForm extends React.Component {
  constructor(props) {
    super(props);
  }

  Run = async () => {
    var validateFields = ['script', 'starttime', 'endtime', 'logdate', 'viewtype'];
    if (this.props.assetNames != null) {
      validateFields.push('assetname');
    }
    var validatedValues = null;
    this.props.form.validateFields(validateFields, async (err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        validatedValues = values;
      }
    });
    if (validatedValues != null) {
      var assetName = this.props.assetNames == null ? this.props.assetName
        : (this.props.assetName.indexOf("Delta") !== -1 ? "UnifiedLayerDemandDelta.view(" + validatedValues.assetname + ")"
          : "UnifiedLayerDemand.view(" + validatedValues.assetname + ")");
      if (assetName.indexOf("Delta") !== -1) {
        await this.props.RunScript(assetName, validatedValues.script, validatedValues.starttime, validatedValues.endtime, "delta");
      } else {
        await this.props.RunScript(assetName, validatedValues.script, null, validatedValues.logdate, "snapshot");
      }
    }
  };

  Generate = async () => {
    // var validateFields = [];
    // if (this.props.assetNames != null) {
    //   validateFields.push('assetname');
    // }
    // if (this.props.assetName.indexOf("Delta") !== -1) {
    //   validateFields.push('starttime');
    //   validateFields.push('endtime');
    // }
    // else {
    //   validateFields.push('logdate');
    // }
    // validateFields.push('conditions');
    var validatedValues = null;
    this.props.form.validateFields(
      // validateFields,
      (err, values) => {
      // if (!err) {
        if (values.starttime != null && values.endtime != null && values.starttime >= values.endtime) {
          window.alert("End time should be late than start time.");
        }
        else {
          const { keys, querycolumns, queryoperators, queryvalues } = values;
          var queryConditions = [];
          for (var i = 0; i < keys.length; i++) {
            var idx = keys[i];
            var queryCondition = {};
            queryCondition["Column"] = querycolumns[idx];
            queryCondition["Operator"] = queryoperators[idx];
            var queryValues = queryvalues[idx].split(",");
            // If queryValues is null or empty then it should not be added into condition list.
            if (queryValues != null && queryValues.length > 0) {
              queryValues.forEach(function(v, index) {
                this[index] = v.trim();
              }, queryValues);
              queryCondition["Values"] = queryValues;
              queryConditions.push(queryCondition);
            }
          }
          var queryJSON = JSON.stringify(queryConditions);
          validatedValues = values;
          validatedValues.queryconditions = queryJSON
          // var assetName = this.props.assetNames == null ? this.props.assetName
          //   : (this.props.assetName.indexOf("Delta") !== -1 ? "UnifiedLayerDemandDelta.view("+values.assetname+")" : "UnifiedLayerDemand.view("+values.assetname+")");
        }
      // }
    });
    if (validatedValues != null) {
      var assetName = this.props.assetNames == null ?
        this.props.assetName
        : "UnifiedLayerDemandDelta.view(" + validatedValues.assetname + ")";
      await this.GenerateScript(assetName, validatedValues.starttime, validatedValues.endtime, validatedValues.logdate, validatedValues.queryconditions);
    }
  }

  handleReset = () => {
    this.props.form.resetFields();
    this.props.ClearSubmitStatus();
  };

  async GenerateScript(assetName, startTime, endTime, logdate, conditions) {
    var content = {};
    if (logdate == null) {
      content.StartDate = `DateTime.Parse(\"${startTime.format("YYYY-MM-DD")}\")`;
      content.EndDate = `DateTime.Parse(\"${endTime.format("YYYY-MM-DD")}\")`;
    }
    else {
      content.LogDate = `DateTime.Parse(\"${logdate.format("YYYY-MM-DD")}\")`;
    }
    if (conditions != null && conditions != "") {
      content.conditions = conditions;
    }
    var name = this.props.userName.substring(0, this.props.userName.indexOf('@'));
    var methodName = logdate == null ? "GenerateULDDeltaScript" : "GenerateULDScript";
    var path = process.env.REACT_APP_API_URL + `/api/AdHocQuery/${methodName}?UserName=${name}&&ArticleName=${assetName}`;
    await fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.props.accessToken
      },
      body: JSON.stringify(content)
    }).then(
      res => res.json()
    ).then(response => {
      console.log(response);
      this.props.form.setFieldsValue({
        script: response
      });
    }).catch((err) => {
      console.log(err);
    })
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.assetName !== this.props.assetName) {
      this.handleReset();
    }
  }

  add = () => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(id++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys,
    });
  }

  remove = k => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    if (keys.length === 0) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter(key => key !== k),
    });
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;

    var today = new Date();
    today.setDate(today.getDate() - 3);
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    today.setDate(today.getDate() - 3);
    var startDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var isdelta = this.props.assetName.indexOf("Delta") !== -1;
    var currentshortname = this.props.assetName.replace("UnifiedLayerDemand.view(", "").replace("UnifiedLayerDemandDelta.view(", "").replace(")", "");

    var conditionalColumns = this.props.conditionalColumns;
    console.log("[uldform::render] conditionalColumns", conditionalColumns);

    const handleAssetChange = (value) => {
      var fullName = this.props.prefix + "(" + value + ")";
      this.props.GetConditionalColumns("CosmosView", fullName, this.props.accessToken)
    };

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 },
      },
    };

    getFieldDecorator('keys', { initialValue: [] });
    const keys = getFieldValue('keys');
    const formItems = keys.map((k, index) => (
      <Form.Item{
        ...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
        name="queryconditions"
        style={{ display: 'inline' }}
        label={index === 0 ? 'Query conditions' : ''}
        required={false}
        key={k}
      >
        {getFieldDecorator(`querycolumns[${k}]`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              whitespace: true,
              message: "Please select query column or delete this field.",
            },
          ],
        })(
          <Select style={{ width: '32%' }} showSearch>
            {conditionalColumns.map(keyValue => {
              return <Select.Option value={keyValue.Key}>{keyValue.Key}</Select.Option>;
            })}
          </Select>
        )}
        {getFieldDecorator(`queryoperators[${k}]`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              whitespace: true,
              message: "Please select operator or delete this field.",
            },
          ],
        })(
          <Select style={{ width: '8%' }} showSearch>
            <Select.Option value='EQ'>=</Select.Option>
            <Select.Option value='IN'>IN</Select.Option>
            <Select.Option value='GT'>&gt;</Select.Option>
            <Select.Option value='LT'>&lt;</Select.Option>
            <Select.Option value='GE'>&ge;</Select.Option>
            <Select.Option value='LE'>&le;</Select.Option>
          </Select>
        )}
        {getFieldDecorator(`queryvalues[${k}]`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              whitespace: true,
              message: "Please input query values or delete this field.",
            },
          ],
        })(
          <Input placeholder="" style={{ width: '50%' }} />
        )}
        &nbsp;
        {
          <Icon
            className="dynamic-delete-button"
            type="minus-circle-o"
            onClick={() => this.remove(k)}
          />
        }
      </Form.Item>
    ));

    return (
      <Form onSubmit={this.handleSubmit}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        size="small">
        <Form.Item name="assetname" label="Asset Name" >
          {
            this.props.assetNames != null ?
              getFieldDecorator('assetname', { rules: [{ required: true }] })
                (
                  <Select showSearch onChange={handleAssetChange}>
                    {
                      this.props.assetNames.map(assetName => {
                        var shortName = assetName.replace("UnifiedLayerDemand.view(", "").replace("UnifiedLayerDemandDelta.view(", "").replace(")", "");
                        return <Select.Option value={shortName} >{shortName}</Select.Option>
                      })
                    }
                  </Select>
                )
              :
              <div><span>{currentshortname}</span></div>
          }
        </Form.Item>
        {
          isdelta ?
            null :
            <Form.Item name="logdate" label="Log Date" {...config}>
              {
                getFieldDecorator('logdate', { initialValue: moment(date, 'YYYY-MM-DD'), rules: [{ required: true }] })
                  (
                    <DatePicker defaultValue={moment(date, 'YYYY-MM-DD')} />
                  )
              }
            </Form.Item>
        }
        {
          isdelta ?
            <Form.Item name="starttime" label="Start Date" {...config}>
              {
                getFieldDecorator('starttime', { initialValue: moment(startDate, 'YYYY-MM-DD'), rules: [{ required: true }] })
                  (
                    <DatePicker defaultValue={moment(startDate, 'YYYY-MM-DD')} />
                  )
              }
            </Form.Item>
            : null
        }
        {
          isdelta ?
            <Form.Item name="endtime" label="End Date" {...config}>
              {
                getFieldDecorator('endtime', { initialValue: moment(date, 'YYYY-MM-DD'), rules: [{ required: true }] })
                  (
                    <DatePicker defaultValue={moment(date, 'YYYY-MM-DD')} />
                  )
              }
            </Form.Item>
            : null
        }
        {
          isdelta ?
          <Form.Item name="viewtype" noStyle initialValue={'delta'}><Input type="hidden"></Input></Form.Item>
            : <Form.Item name="viewtype" noStyle initialValue={'snapshot'}><Input type="hidden"></Input></Form.Item>
        }
        {/* <Form.Item name="conditions" label="Query conditions (preview)" help="Please fill in the query in JSONArray format. For example: [{&quot;Column&quot;:&quot;CustomerId&quot;,&quot;Operator&quot;:&quot;EQ&quot;,&quot;Values&quot;:[731]},{&quot;Column&quot;:&quot;AccountId&quot;,&quot;Operator&quot;:&quot;IN&quot;,&quot;Values&quot;:[31,58,60,63,75,81]},{&quot;Column&quot;:&quot;CountryCode&quot;,&quot;Operator&quot;:&quot;IN&quot;,&quot;Values&quot;:[&quot;US&quot;,&quot;UK&quot;]},{&quot;Column&quot;:&quot;FinancialStatusId&quot;,&quot;Operator&quot;:&quot;EQ&quot;,&quot;Values&quot;:[36]}]&#10;Only INTEGER and STRING columns are supported as query conditions, the following operators are supported: EQ (=), IN (in), GT (&gt;), LT (&lt;), GE (&gt;=), LE (&lt;=).">
          {
            getFieldDecorator('conditions', { initialValue: "", rules: [{ required: false }] })
              (
                <Input.TextArea rows={4} />
              )
          }
        </Form.Item> */}
        {formItems}
        <Form.Item {...formItemLayoutWithOutLabel} name="btnaddfiled" style={{ display: 'inline' }}>
          <Button type="dashed" onClick={this.add} style={{ width: '60%' }}>
            <Icon type="plus" /> Add query condition field
          </Button>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <LoadingButton text={"Generate Script"} onClick={this.Generate} />
        </Form.Item>
        <Form.Item name="script" label="Generated Script">
          {
            getFieldDecorator('script', { initialValue: "", rules: [{ required: true, message: 'Please generate script before run it.' }] })
              (
                <Input.TextArea rows={4} />
              )
          }
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <div><span>Please don't run too heavy script here!</span></div>
          {/* <Button onClick={this.Run}>Run</Button> */}
          <LoadingButton text={"Run"} onClick={this.Run} />
          <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>Clear</Button>
          <div><span style={{ color: '#f5222d' }}>{this.props.submitstatus}</span></div>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: 'generate' })(GenerateULDForm);


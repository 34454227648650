import React from 'react';
import GenerateC2CForm from './query/c2cform';
import DataRegistryForm from './query/c2sform.js';
import GenerateC2SForm from './query/c2sform.js';
import GenerateULDForm from './query/uldform.js';
import GenerateULPForm from './query/ulpform.js';
import JobHistoryTable from './query/jobhistorytable';
import { Collapse } from 'antd';
import moment from 'moment';

const { Panel } = Collapse;

class AdhocQuery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      historyData: [],
      submitstatus: ''
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.assetName !== this.props.assetName && this.props.accessToken != null) {
      var userName = this.props.userName;
      var accessToken = this.props.accessToken;
      var assetName = nextProps.assetName;
      this.setState({ historyData: [] });
      this.setState({ submitstatus: '' });
      this.GetHistoryQuery(userName, accessToken, assetName);
    }
  }

  componentWillMount() {
    console.log(this.props)
    if (this.props.accessToken != null) {
      var userName = this.props.userName;
      var accessToken = this.props.accessToken;
      var assetName = this.props.assetName;
      var viewType = "";
      if (assetName.startsWith("C2C") || assetName.startsWith("Unified")) {
        viewType = "CosmosView";
      } else {
        viewType = "SubstrateView";
      }
      this.setState({ historyData: [] });
      this.setState({ submitstatus: '' });
      this.GetConditionalColumns(viewType, assetName, accessToken)
      this.GetHistoryQuery(userName, accessToken, assetName);
    }
  }

  GetConditionalColumns(viewType, assetName, accessToken) {
    var path = process.env.REACT_APP_API_URL + "/api/AdHocQuery/GetConditionalColumns?viewType=" + viewType + "&articleName=" + assetName;
    fetch(path, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + accessToken
      }
    }).then(
      res => res.json()
    ).then(response => {
      this.setState({ conditionalColumns: response.Entity });
    }).catch((err) => {
      console.log(err);
    })
  }

  GetHistoryQuery(userName, accessToken, assetName) {
    var path = process.env.REACT_APP_API_URL + "/api/AdHocQuery/GetSubmitHistory?userName=" + userName + "&assetName=" + assetName;
    fetch(path, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + accessToken
      }
    }).then(
      res => res.json()
    ).then(response => {
      console.log(response);
      var entity = response.Entity;
      if (entity != null) {
        var historyData = [];
        for (let i = 0; i < entity.length; i++) {
          const job = entity[i];
          historyData[i] = {
            'id': job.ID,
            'user_name': job.UserName,
            'asset_name': job.AssetName,
            'job_id': job.JobId,
            'job_name': job.JobName,
            'running_platform': job.RunningPlatform,
            'job_type': job.JobType,
            'job_type_string': job.JobTypeString,
            'job_status': job.JobStatus,
            'job_status_string': job.JobStatusString,
            'job_url': job.JobUrl,
            'submit_time': moment(job.SubmitTime).format('YYYY-MM-DDTHH:mm:ss'),
            'complete_time': moment(job.CompleteTime).format('YYYY-MM-DDTHH:mm:ss'),
            'output_path': job.OutputPath,
            'script': job.Script,
            'description': job.Description
            // 'post_processing_result': job.PostProcessingResult
          };
        }
        this.setState({ historyData: historyData });
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  async RunScript(assetName, script, starttime, endtime, viewtype) {
    this.setState({ submitstatus: 'Submitting in progress...' });
    console.log("RunScript: " + assetName + " " + " " + starttime + " " + endtime + " " + viewtype);
    var userName = this.props.userName;
    var accessToken = this.props.accessToken;
    var content = "=" + script;
    var startTimeFormatted = starttime == null ? null : starttime.format("YYYY-MM-DD HH:00:00")
    var endTimeFormatted = endtime == null ? null : endtime.format("YYYY-MM-DD HH:00:00")
    var path = process.env.REACT_APP_API_URL
      + `/api/AdHocQuery/SubmitC2CJob?UserName=${userName}&ArticleName=${assetName}&ViewType=${viewtype}&StartDate=${startTimeFormatted}&EndDate=${endTimeFormatted}`;
    var req_content = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + accessToken
      },
      body: content
    }
    await fetch(path, req_content).then(
      res => res.json()
    ).then(response => {
      if (!response.HasErrors) {
        var job = response.Entity;
        var job = {
          'id': job.ID,
          'user_name': job.UserName,
          'asset_name': job.AssetName,
          'job_id': job.JobId,
          'job_name': job.JobName,
          'running_platform': job.RunningPlatform,
          'job_type': job.JobType,
          'job_type_string': job.JobTypeString,
          'job_status': job.JobStatus,
          'job_status_string': job.JobStatusString,
          'job_url': job.JobUrl,
          'submit_time': moment(job.SubmitTime).format('YYYY-MM-DD HH:mm:ss'),
          'complete_time': job.CompleteTime,
          'output_path': job.OutputPath,
          'script': job.Script,
          'description': job.Description
        }
        this.state.historyData.unshift(job);

        this.setState({ submitstatus: 'Job is submitted. Please check Query Result for details.' });
      }
      else {
        this.setState({ submitstatus: response.Errors[0].Code + response.Errors[0].Message });
      }
    }).catch((err) => {
      console.log(err);
      this.setState({ submitstatus: 'Submit failed.' });
    })
  }

  async SubmitC2SQuery(userName, assetName, starttime, endtime, viewtype, conditions, accessToken) {
    this.setState({ submitstatus: 'Submitting in progress...' });
    var articleType = 0
    if (assetName.startsWith("DataRegistry")) {
      articleType = 1
    }
    var startDate = moment(starttime).format('YYYY-MM-DD HH:mm:ss');
    var endDate = moment(endtime).format('YYYY-MM-DD HH:mm:ss');
    var path = process.env.REACT_APP_API_URL
      + `/api/AdHocQuery/SubmitC2SJob?userName=${userName}&articleName=${assetName}&articleType=${articleType}&viewType=${viewtype}&startDate=${startDate}&endDate=${endDate}&queryConditions=${conditions}&limit=10000`;
    var req_content = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + accessToken
      }
    }
    await fetch(path, req_content).then(
      res => res.json()
    ).then(response => {
      if (!response.HasErrors) {
        var entity = response.Entity;
        var job = {
          'id': entity.ID,
          'user_name': entity.UserName,
          'asset_name': entity.AssetName,
          'job_id': entity.JobId,
          'job_name': entity.JobName,
          'running_platform': entity.RunningPlatform,
          'job_type': entity.JobType,
          'job_type_string': entity.JobTypeString,
          'job_status': entity.JobStatus,
          'job_status_string': entity.JobStatusString,
          'submit_time': moment(entity.SubmitTime).format('YYYY-MM-DD HH:mm:ss'),
          'job_url': entity.JobUrl,
          'complete_time': entity.CompleteTime,
          'output_path': entity.OutputPath,
          'script': entity.Script,
          'description': entity.Description
          // 'post_processing_result': entity.PostProcessingResult
        }
        // alert(this.state.historyData.push(job))
        // var job_count = this.state.historyData.push(job);
        var job_count = this.state.historyData.unshift(job);
        // alert(job_count)

        this.setState({ submitstatus: 'Job is submitted. Please check Query Result for details.' });
      } else {
        this.setState({ submitstatus: response.Errors[0].Code + response.Errors[0].Message });
      }
    }).catch((err) => {
      console.log(err);
      this.setState({ submitstatus: 'Submit failed.' });
    })
  }

  UpdateJobInfo(id) {
    var path = process.env.REACT_APP_API_URL + "/api/AdHocQuery/Getjobstatus?id=" + id;
    fetch(path, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.props.accessToken
      }
    }).then(
      res => res.json()
    ).then(response => {
      console.log(response);
      var entity = response.Entity;
      if (entity != null) {
        var historyData = this.state.historyData;
        for (let i = 0; i < historyData.length; i++) {
          const job = historyData[i];
          if (job.id === id) {
            job.job_url = entity.JobUrl;
            job.job_status = entity.JobStatus;
            job.job_status_string = entity.JobStatusString;
            this.setState({ historyData: historyData });
            break;
          }
        }
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  ClearSubmitStatus() {
    this.setState({ submitstatus: '' });
  }

  render() {
    var userName = this.props.userName;
    var accessToken = this.props.accessToken;
    var assetName = this.props.assetName;

    return (
      <Collapse bordered={false} onChange={this.callback} >
        <Panel header="Query" key="1">
          {
            assetName.startsWith("C2CMasterView") ?
              <GenerateC2CForm accessToken={accessToken} userName={userName}
                assetName={assetName} assetNames={this.props.assetNames} conditionalColumns={this.state.conditionalColumns} submitstatus={this.state.submitstatus}
                RunScript={async (assetName, script, starttime, endtime, viewtype) => { await this.RunScript(assetName, script, starttime, endtime, viewtype) }}
                GetConditionalColumns={async (viewtype, assetName, accessToken) => { await this.GetConditionalColumns(viewtype, assetName, accessToken) }}
                ClearSubmitStatus={() => { this.ClearSubmitStatus() }} />
              : null
          }
          {
            assetName.startsWith("C2SView") ?
              <GenerateC2SForm prefix="C2SView" accessToken={accessToken} userName={userName}
                assetName={assetName} assetNames={this.props.assetNames} conditionalColumns={this.state.conditionalColumns} submitstatus={this.state.submitstatus}
                SubmitC2SQuery={async (userName, assetName, starttime, endtime, viewtype, accessToken) => { await this.SubmitC2SQuery(userName, assetName, starttime, endtime, viewtype, accessToken) }}
                GetConditionalColumns={async (viewtype, assetName, accessToken) => { await this.GetConditionalColumns(viewtype, assetName, accessToken) }}
                ClearSubmitStatus={() => { this.ClearSubmitStatus() }} />
              : null
          }
          {
            assetName.startsWith("DataRegistry") ?
              <DataRegistryForm prefix="DataRegistry" accessToken={accessToken} userName={userName}
                assetName={assetName} assetNames={this.props.assetNames} conditionalColumns={this.state.conditionalColumns} submitstatus={this.state.submitstatus}
                SubmitC2SQuery={async (userName, assetName, starttime, endtime, viewtype, accessToken) => { await this.SubmitC2SQuery(userName, assetName, starttime, endtime, viewtype, accessToken) }}
                GetConditionalColumns={async (viewtype, assetName, accessToken) => { await this.GetConditionalColumns(viewtype, assetName, accessToken) }}
                ClearSubmitStatus={() => { this.ClearSubmitStatus() }} />
              : null
          }
          {
            assetName.startsWith("UnifiedLayerPerf") ?
              <GenerateULPForm accessToken={accessToken} userName={userName}
                assetName={assetName} assetNames={this.props.assetNames} conditionalColumns={this.state.conditionalColumns} submitstatus={this.state.submitstatus}
                RunScript={async (assetName, script, starttime, endtime, viewtype) => { await this.RunScript(assetName, script, starttime, endtime, viewtype) }}
                GetConditionalColumns={async (viewtype, assetName, accessToken) => { await this.GetConditionalColumns(viewtype, assetName, accessToken) }}
                ClearSubmitStatus={() => { this.ClearSubmitStatus() }} />
              : null
          }
          {
            assetName.startsWith("UnifiedLayerDemand") ?
              assetName.indexOf("Delta") ?
                <GenerateULDForm accessToken={accessToken} userName={userName}
                  assetName={assetName} assetNames={this.props.assetNames} conditionalColumns={this.state.conditionalColumns} submitstatus={this.state.submitstatus}
                  RunScript={async (assetName, script, starttime, endtime, viewtype) => { await this.RunScript(assetName, script, starttime, endtime, viewtype) }}
                  ClearSubmitStatus={() => { this.ClearSubmitStatus() }} />
                :
                <GenerateULDForm accessToken={accessToken} userName={userName}
                  assetName={assetName} assetNames={this.props.assetNames} conditionalColumns={this.state.conditionalColumns} submitstatus={this.state.submitstatus}
                  RunScript={async (assetName, script, logdate, viewtype) => { await this.RunScript(assetName, script, null, logdate, viewtype) }}
                  GetConditionalColumns={async (viewtype, assetName, accessToken) => { await this.GetConditionalColumns(viewtype, assetName, accessToken) }}
                  ClearSubmitStatus={() => { this.ClearSubmitStatus() }} />
              : null
          }
        </Panel>
        <Panel header="Query Results" key="2">
          <JobHistoryTable historyData={this.state.historyData}
            assetName={assetName} accessToken={accessToken} userName={userName}
            UpdateJobInfo={(id) => { this.UpdateJobInfo(id) }} />
          <br />
        </Panel>
      </Collapse>
    );
  }
}

export default AdhocQuery;
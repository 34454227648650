import React from 'react';
import { Table, Input, Button } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

class ConsumerTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        searchText: '',
        searchedColumn: '',
        data: [],
        columns: [
            {
              title: 'Originator',
              dataIndex: 'originator',
              key: 'originator',
              width: 400,
              sorter: (a, b) => a.originator.localeCompare(b.originator),
              ...this.getColumnSearchProps('originator')
            },
            {
              title: 'Environment',
              dataIndex: 'env',
              key: 'env',
              width: 300
            },
            {
              title: 'Contact',
              dataIndex: 'user',
              key: 'user',
              width: 300
            }
          ]
    };
  }

  GetConsumers(accessToken, assetName) {
    var path = process.env.REACT_APP_API_URL+"/api/Consumer?assetName="+assetName;
    fetch(path, {
      method:'GET',
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Authorization': 'Bearer ' + accessToken
      }
    }).then(
        res=>res.json()
    ).then(response=>{
        console.log(response);
        var consumers=[];
        for (let i = 0; i < response.length; i++) {
          consumers[i] = {
            'originator': response[i].Originator,
            'user': response[i].User,
            'env':  response[i].Env
          };
        }
        this.setState({ data: consumers });
    }).catch((err)=>{
      console.log(err);
    })
  }

  componentWillUpdate(nextProps, nextState){
    if(nextProps.assetName !== this.props.assetName && this.props.accessToken != null)
    {
      var accessToken = this.props.accessToken;
      var assetName = nextProps.assetName;
      this.GetConsumers(accessToken, assetName);
    }
  }

  componentWillMount()
  {
    if(this.props.accessToken != null)
    {
      var accessToken = this.props.accessToken;
      var assetName = this.props.assetName;
      this.GetConsumers(accessToken, assetName);
    }
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {

    return (
      <div style={{width:800}}>  
        <Table bordered 
            dataSource={this.state.data}
            columns={this.state.columns}
            pagination={{ pageSize: 10, position: 'bottom' }}
        />
        <br/><br/>
      </div>
      
    );
  }
}

export default ConsumerTable;
import React from 'react';
import { Descriptions } from 'antd';
import 'antd/dist/antd.css';
import '../index.css';
import JobHistoryTable from './jobhistorytable';
import LoadingButton from '../button';
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Switch
} from 'antd';
import moment from 'moment';
import { Collapse } from 'antd';

const { Panel } = Collapse;

const config = {
  rules: [{ type: 'object', required: true, message: 'Please select time.' }],
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 4,
    },
  },
};

class RSAAdsDropFormST2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      historyData: [],
      submitstatus: '',
      availableDates: []
    };
  }

  componentWillMount() {
    if (this.props.accessToken != null) {
      var userName = this.props.userName;
      var accessToken = this.props.accessToken;
      var assetName = "RSAAdsDrop-Stage1";
      this.setState({ historyData: [] });
      this.setState({ submitstatus: '' });
      this.GetHistoryQuery(userName, accessToken, assetName);
      this.GetDataAvailability(accessToken);
    }
  }

  GetDataAvailability(accessToken) {
    var path = process.env.REACT_APP_API_URL + "/api/DataAnalysisTools/GetAvailableDates?jobType=2";
    fetch(path, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + accessToken
      }
    }).then(
      res => res.json()
    ).then(response => {
      console.log("GetDataAvailability.response", response)
      var availableDates = []
      if (response != null) {
        for (let i = 0; i < response.length; i++) {
          availableDates[i] = response[i];
        }
      }
      console.log("GetDataAvailability.availableDates", availableDates)
      this.setState({ availableDates: availableDates });
    }).catch((err) => {
      console.log(err);
    })
  }

  GetHistoryQuery(userName, accessToken, assetName) {
    var path = process.env.REACT_APP_API_URL + "/api/AdHocQuery/GetSubmitHistory?userName=" + userName + "&assetName=" + assetName;
    fetch(path, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + accessToken
      }
    }).then(
      res => res.json()
    ).then(response => {
      console.log(response);
      var entity = response.Entity;
      if (entity != null) {
        var historyData = [];
        for (let i = 0; i < entity.length; i++) {
          const job = entity[i];
          historyData[i] = {
            'id': job.ID,
            'user_name': job.UserName,
            'asset_name': job.AssetName,
            'job_id': job.JobId,
            'job_name': job.JobName,
            'running_platform': job.RunningPlatform,
            'job_type': job.JobType,
            'job_type_string': job.JobTypeString,
            'job_status': job.JobStatus,
            'job_status_string': job.JobStatusString,
            'job_url': job.JobUrl,
            'submit_time': moment(job.SubmitTime).format('YYYY-MM-DDTHH:mm:ss'),
            'complete_time': job.CompleteTime,
            'output_path': job.OutputPath,
            'script': job.Script,
            'description': job.Description
            // 'post_processing_result': job.PostProcessingResult
          };
        }
        this.setState({ historyData: historyData });
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  Run = async () => {
    this.setState({ submitstatus: 'Submitting in progress...' });
    var validateFields = ['starttime', 'endtime', 'customerid'];
    if (this.props.assetNames != null) {
      validateFields.push('assetname');
    }
    var validated = false;
    var starttime, endtime, customerid;


    this.props.form.validateFields(validateFields, (err, values) => {
      if (!err) {
        starttime = values.starttime;
        endtime = values.endtime;
        customerid = values.customerid;
        validated = true;
      }
    });

    this.props.form.validateFields(validateFields, (err, values) => {
      if (!err) {
        var userName = this.props.userName;
        // var assetName = this.props.assetNames == null ? 
        //   this.props.assetName : values.assetname;
        var accessToken = this.props.accessToken;
        this.SubmitRSAAdsDropQuery(userName, values.starttime, values.endtime, values.customerid, accessToken);
      }
    });

    if (validated) {
      var userName = this.props.userName;
      var accessToken = this.props.accessToken;
      var startDate = moment(starttime).format('YYYY-MM-DD');
      var endDate = moment(endtime).format('YYYY-MM-DD');
      var path = process.env.REACT_APP_API_URL
        + `/api/DataAnalysisTools/SubmitRSAAdsDropJob?userName=${userName}&stage=1&startDate=${startDate}&endDate=${endDate}&customerIds=${customerid}`;
      var req_content = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + accessToken
        }
      }

      await fetch(path, req_content).then(
        res => res.json()
      ).then(response => {
        if (!response.HasErrors) {
          var entity = response.Entity;
          var job = {
            'id': entity.ID,
            'user_name': entity.UserName,
            'asset_name': entity.AssetName,
            'job_id': entity.JobId,
            'job_name': entity.JobName,
            'running_platform': entity.RunningPlatform,
            'job_type': entity.JobType,
            'job_type_string': entity.JobTypeString,
            'job_status': entity.JobStatus,
            'job_status_string': entity.JobStatusString,
            'submit_time': moment(entity.SubmitTime).utc().format('YYYY-MM-DDTHH:mm:ss'),
            'job_url': entity.JobUrl,
            'complete_time': entity.CompleteTime,
            'output_path': entity.OutputPath,
            'script': entity.Script,
            'description': entity.Description
          }
          var job_count = this.state.historyData.unshift(job);

          this.setState({ submitstatus: 'Job is submitted. Please check Query Result for details.' });
        } else {
          this.setState({ submitstatus: response.Errors[0].Code + response.Errors[0].Message });
        }
      }).catch((err) => {
        console.log(err);
        this.setState({ submitstatus: 'Submit failed.' });
      })
    }
  }

  ClearSubmitStatus() {
    this.setState({ submitstatus: '' });
  }

  handleReset = () => {
    this.props.form.resetFields();
    this.ClearSubmitStatus();
  };

  UpdateJobInfo(id) {
    var path = process.env.REACT_APP_API_URL + "/api/AdHocQuery/Getjobstatus?id=" + id;
    fetch(path, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.props.accessToken
      }
    }).then(
      res => res.json()
    ).then(response => {
      console.log(response);
      var entity = response.Entity;
      if (entity != null) {
        var historyData = this.state.historyData;
        for (let i = 0; i < historyData.length; i++) {
          const job = historyData[i];
          if (job.id === id) {
            job.job_status = entity.JobStatus;
            job.job_status_string = entity.JobStatusString;
            job.job_url = entity.JobUrl;
            this.setState({ historyData: historyData });
            break;
          }
        }
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    var userName = this.props.userName;
    var accessToken = this.props.accessToken;
    var assetName = "RSAAdsDrop-Stage1";
    console.log("render: " + this.state.availableDates)

    const disabledDate = (date) => {
      var dateString = moment(date).format('YYYY-MM-DD')
      return !this.state.availableDates.includes(dateString)
    }

    return (
      <Collapse bordered={false} onChange={this.callback} defaultActiveKey={["0"]}>
        <Panel header="Description" key="0">
          <Descriptions column={1} size={"small"} title="This tool pulls daily servable RSA count for the given list of customers between Start Date and End Date.">
          </Descriptions>
        </Panel>
        <Panel header="Query" key="1">
          <p>Please expect 30 Mins for the job to complete.<br />Please be aware that the source data usually have two days delay.<br />Please be aware the max number of days supported is 1000.</p>
          <Form onSubmit={this.handleSubmit}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            layout="horizontal"
            size="small">
            <Form.Item name="starttime" label="Start Time" {...config}>
              {
                getFieldDecorator('starttime', { initialValue: moment(this.state.availableDates.slice(-1), 'YYYY-MM-DD').add(-7, 'days') })
                  (
                    <DatePicker showTime={{ hideDisabledOptions: true }}
                      format="YYYY-MM-DD"
                      disabledDate={disabledDate}
                    />
                  )
              }
            </Form.Item>
            <Form.Item name="endtime" label="End Time" {...config}>
              {
                getFieldDecorator('endtime', { initialValue: moment(this.state.availableDates.slice(-1), 'YYYY-MM-DD') })
                  (
                    <DatePicker showTime={{ hideDisabledOptions: true }}
                      format="YYYY-MM-DD"
                      disabledDate={disabledDate}
                    />
                  )
              }
            </Form.Item>
            <Form.Item label="Customer ID" name="customerid" style={{ display: 'inline' }} help="Please separate multiple customer IDs with comma. For example: 1, 2, 3">
              {
                getFieldDecorator('customerid', { initialValue: "", rules: [{ required: true }] })
                  (
                    <Input.TextArea rows={1} />
                  )
              }
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <LoadingButton text={"Run"} onClick={this.Run} />
              <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>Clear</Button>
              <div><span style={{ color: '#f5222d' }}>{this.state.submitstatus}</span></div>
            </Form.Item>
          </Form>
        </Panel>
        <Panel header="Query Results" key="2">
          <JobHistoryTable historyData={this.state.historyData}
            assetName={assetName} accessToken={accessToken} userName={userName}
            UpdateJobInfo={(id) => { this.UpdateJobInfo(id) }} />
          <br />
        </Panel>
      </Collapse>
    );
  }
}

export default Form.create({ name: 'generate' })(RSAAdsDropFormST2);
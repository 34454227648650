import React from 'react';
import { Table } from 'antd';
import ResizeableTitle from './util';

class ResizeTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: []
    };
  }

  components = {
    header: {
      cell: ResizeableTitle,
    },
  };

  handleResize = index => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      return { columns: nextColumns };
    });
  };

  componentWillMount() {
    this.setState({ columns: this.props.columns });
    this.setState({ data: this.props.data });
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.data !== this.props.data) {
      this.setState({ columns: nextProps.columns });
      this.setState({ data: nextProps.data });
    }
  }

  render() {
    var columns = this.state.columns;
    var data = this.state.data;

    const mapColumns = columns.map((col, index) => ({
      ...col,
      onHeaderCell: column => ({
        width: column.width,
        onResize: this.handleResize(index),
      }),
    }));

    return (
      <Table bordered components={this.components}
        dataSource={data}
        columns={mapColumns}
        pagination={{ pageSize: this.props.pageSize, position: this.props.position }}
        scroll={{ x: this.props.scrollx, y: this.props.scrolly }}
        showHeader={this.props.showHeader}
      />
    );
  }
}

export default ResizeTable;
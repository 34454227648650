import React from 'react';
import { Table, Input, InputNumber, Form, Popconfirm, Button, Modal } from 'antd';
import ResizeableTitle from './util';

const data = [];

function generateData(columns, searchValue = null)
{
  data.splice(0);
  if(columns != null)
  {
    for (let i = 0; i < columns.length; i++) {
      const column = columns[i];
      data.push({ 
        key: i.toString(),
        name: column.Name,
        coloredName: searchValue !== null ? window.ReplaceColor(column.Name, searchValue) : column.Name,
        description: column.Description,
        type: column.Type,
        defaultvalue: column.DefaultValue,
        cardinality: column.Cardinality,
        values: column.ColumnValues,
        columnValueSource: column.ColumnValueSource
       });
    }
  }
};

const EditableContext = React.createContext();

const InnerColumn = [
  {
    title: 'Value',
    dataIndex: 'Value',
    width: 150,
    editable: false,
    sorter: (a, b) => a.Value - b.Value,
    render: (text, record) => {
      var value = record.Value == null && record.Count != null ? "NULL" : record.Value;
      return(
        <span>{value}</span>
      )
    }
  },
  {
    title: 'Count',
    dataIndex: 'Count',
    width: 150,
    editable: false,
    // filters:[
    //   {
    //     text: 'NOT NULL',
    //     value: 'NOT NULL',
    //   },
    // ],
    // onFilter: (value, record) => record.Count !== null,
    sorter: (a, b) => a.Count - b.Count,
    render: (text, record) => {
      var value = record.Count === null ? "N/A" : record.Count;
      return(
        <span>{value}</span>
      )
    }
  },
  {
    title: 'Name',
    dataIndex: 'EnumName',
    width: 250,
    editable: false
  },
  {
    title: 'Description',
    dataIndex: 'Description',
    width: 450,
    editable: false
  }];

class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === 'number') {
      return <InputNumber />;
    }
    return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              initialValue: record[dataIndex],
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      data, 
      editingKey: '',
      visibleMap: new Map(),
      visible: false,
      columns: [
        {
          title: 'Name',
          dataIndex: 'coloredName',
          width: props.tableWidth*0.15,
          editable: false,
          sorter: (a, b) => a.name.localeCompare(b.name)
        },
        {
          title: 'Type',
          dataIndex: 'type',
          width: props.tableWidth*0.1,
          editable: false,
        },
        {
          title: 'Default Value',
          dataIndex: 'defaultvalue',
          width: props.tableWidth*0.1,
          editable: false,
        },
        {
          title: 'Description',
          dataIndex: 'description',
          width: this.props.assetName.indexOf("(") !==-1 ? props.tableWidth*0.44 : props.tableWidth*0.58,
          editable: true,
        },
        {
          title: 'Cardinality',
          dataIndex: 'cardinality',
          width: props.tableWidth*0.07,
          editable: false,
        },
        {
          title: 'Values',
          dataIndex: 'values',
          width: props.tableWidth*0.07,
          render: (text, record) => {
            var columnName = record.name;
            var values = record.values;
            var source = record.columnValueSource;
            var show = values != null && values.length > 0;
            var title = this.props.assetName +": "+ columnName + " Values";
            return (
              show ?
              <div>
              <Button onClick={() => this.showModal(columnName)}>
                View
              </Button>
              <Modal
                title={title}
                centered
                width={1000}
                visible={this.state.visibleMap.get(columnName) === true ? true : false}
                onOk={() => this.notShowModal(columnName)}
                onCancel={() => this.notShowModal(columnName)}
              >
                <Table 
                  size="middle"
                  bordered
                  dataSource={values}
                  columns={InnerColumn}
                  pagination={{
                    pageSize: 10
                  }}
                />
                {
                  source != null ? 
                  <span>Source of Name and Description: {source.SourceName}. Detail: {source.Detail}</span>
                  : null
                }
              </Modal>
            </div>
            : null
            )
          }
        },
        {
          title: 'Operation',
          dataIndex: 'operation',
          width: props.tableWidth*0.07,
          render: (text, record) => {
            const { editingKey } = this.state;
            const editable = this.isEditing(record);
            return editable ? (
              <span>
                <EditableContext.Consumer>
                  {form => (
                    <span>
                      <a
                        onClick={() => this.save(form, record.key)}
                        style={{ marginRight: 8 }}
                      >
                        Save
                      </a>
                      <a
                        onClick={() => this.cancel(form, record.key)}
                        style={{ marginRight: 8 }}
                      >
                        Cancel
                      </a>
                  </span>
                  )}
                </EditableContext.Consumer>
              </span>
            ) : (
              <a disabled={editingKey !== ''} onClick={() => this.edit(record.key)}>
                Edit
              </a>
            );
          },
        },
      ]
    };
  }

  getModalVisible(columnName) {
    return this.state.visibleMap.get(columnName) === true ? true : false;
  }

  showModal(columnName) {
    this.state.visibleMap.set(columnName, true);
    this.setState({
      visible: true,
    });
  }

  notShowModal(columnName) {
    this.state.visibleMap.set(columnName, false);
    this.setState({
      visible: false,
    });
  };

  isEditing = record => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: '' });
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      var data = this.state.data;
      const index = data.findIndex(item => key === item.key);
      if (index > -1) {
        const item = data[index];
        var oldCDescription = item.description;
        data.splice(index, 1, {
          ...item,
          ...row,
        });
        this.props.columns[index].Description = row.description;
        this.updateColumn(this.props.assetType, 
          this.props.assetName, 
          item.name,
          row.description,
          this.props.isParameters,
          this.props.accessToken);
        this.updateUserAuditInfo(
          this.props.assetName,
          item.name,
          this.props.userName,
          oldCDescription,
          row.description,
          this.props.accessToken
        );
        this.setState({ data: data, editingKey: '' });
      }
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.assetName !== this.props.assetName)
    {
      // var columnInfos = nextProps.columns;
      // generateData(columnInfos);
      this.setState({ editingKey: '' });
    }
  }

  updateUserAuditInfo(assetName, columnName, userName, oldComment, newComment, accessToken) {
    var content = {
      "AssetName": assetName,
      "ColumnName": columnName,
      "UserName": userName,
      "OldComment": oldComment,
      "NewComment": newComment
    };
    var path = process.env.REACT_APP_API_URL+'/api/UserAuditInfo';
    fetch(path, {
      method:'POST',
      headers:{
        'Content-Type':'application/json;charset=UTF-8',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Authorization': 'Bearer ' + accessToken
      },
      body:JSON.stringify(content)
    }).catch((err)=>{
      console.log(err);
    })
  }

  updateColumn(type, assetName, columnName, description, isParameters, accessToken){
    var content = {
      "Type": type,
      "Name": assetName,
      "ColumnName": columnName,
      "Description": description,
      "IsParameters": isParameters
    };
    var path = process.env.REACT_APP_API_URL+'/api/DataAsset/Column'
    fetch(path, {
      method:'POST',
      headers:{
        'Content-Type':'application/json;charset=UTF-8',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Authorization': 'Bearer ' + accessToken
      },
      body:JSON.stringify(content)
    }).catch((err)=>{
      console.log(err);
    })
  }

  handleResize = index => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      return { columns: nextColumns };
    });
  };

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
      header: {
        cell: ResizeableTitle,
      },
    };

    var tempColumns = this.props.assetName.indexOf("(") ===-1 ? this.state.columns.slice(0, 4) : this.state.columns;
    var columns = tempColumns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    columns = columns.map((col, index) => ({
      ...col,
      onHeaderCell: column => ({
        width: column.width,
        onResize: this.handleResize(index),
      }),
    }));

    generateData(this.props.columns, this.props.searchValue);

    return (
      <EditableContext.Provider value={this.props.form}>
        <Table 
          size="middle"
          components={components}
          bordered
          dataSource={this.state.data}
          columns={columns}
          rowClassName="editable-row"
          pagination={{
            onChange: this.cancel,
            position : 'none',
            pageSize: 100
          }}
        />
      </EditableContext.Provider>
    );
  }
}

const EditableFormTable = Form.create()(EditableTable);
export default EditableFormTable;

import React, { Component } from 'react';
import { useRef } from 'react';
import { Modal, Drawer, Form, Button, Input, Tooltip, Table, Space, Upload, message } from 'antd';
import { SmileOutlined, FrownOutlined, SmileFilled, FrownFilled, ImportOutlined, UploadOutlined, ConsoleSqlOutlined } from '@ant-design/icons';
import JobHistoryTable from './query/jobhistorytable';



class Tools extends React.Component {

  constructor(props) {
    super(props);
    var timeoutMap = {};
    this.state = {
      fileList: [],
      visibleMap: new Map(),
      registry_file_list_columns: [
        {
          title: 'File Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Action',
          dataIndex: 'action',
          key: 'action',
          render: (text, record) => (
            <div>
              <Button style={{ marginRight: '8px' }} onClick={() => this.onDownloadfile(record.name)}>Download</Button>
              {/* <Upload {...this.fileUploadProps}>
                        <Button icon={<UploadOutlined /> }>Upload</Button>
                      </Upload> */}
            </div>
          ),
        },
      ],
      registry_change_history_columns: [
        {
          title: 'Submit Time',
          dataIndex: 'submittime',
          key: 'submittime',
          width: '17.5%',
          sorter: (a, b) => a.submittime > b.submittime,
        },
        // {
        //   title: 'Changed By',
        //   dataIndex: 'username',
        //   key: 'username',
        //   width: '17.5%'
        // },
        {
          title: 'Status',
          dataIndex: 'statusstring',
          key: 'statusstring',
          width: '12%'
        },
        {
          title: 'Job Link',
          dataIndex: 'joburl',
          key: 'joburl',
          width: '12.5%',
          render: (text, record) => {
            var job_url = record.joburl;
            var show = (job_url != null && job_url != "");
            if (show) {
              return (<span><a href={job_url} target={"_blank"}>Link</a></span>)
            } else {
              return 'N/A'
            }
          }
        },
        {
          title: 'Verified',
          dataIndex: 'verified',
          key: 'verified',
          width: '12.5%',
          render: (text, record) => {
            var status = record.status
            if (status >= 4) {
              if (timeoutMap[record.id] != null) {
                console.log("Removing interval of data-registry job: " + record.id);
                window.clearInterval(timeoutMap[record.id]);
              }
              if (record.verified) {
                return 'YES'
              } else {
                return 'NO'
              }
            } else {
              if (timeoutMap[record.id] == null) {
                // TODO: Not supported.
                // console.log("Creating interval for data-registry job: " + record.id);
                // timeoutMap[record.id] = window.setInterval(() => this.props.UpdateJobInfo(record.id), 15000);
              }
              return 'NO';
            }
          }
        },
        {
          title: 'Pull request',
          dataIndex: 'pullrequest',
          key: 'pullrequest',
          width: '12.5%',
          render: (text, record) => {
            if (record.verified) {
              return (<span><a href={record.pullrequest} target={"_blank"}>Link</a></span>)
            } else {
              return 'N/A'
            }
          }
        },
        {
          title: 'Error Message',
          dataIndex: 'errormessage',
          key: 'errormessage',
          width: '17.5%',
          render: (text, record) => {
            var id = record.id;
            var error_message = record.errormessage;
            var show = (error_message != null && error_message != "");
            return (
              show ?
                <div>
                  <Button onClick={() => this.showModal(id)}>
                    View
                  </Button>
                  <Modal
                    title="Error message"
                    centered
                    width={1000}
                    visible={this.state.visibleMap.get(id) === true ? true : false}
                    onOk={() => this.notShowModal(id)}
                    onCancel={() => this.notShowModal(id)}>
                    <p>{error_message.split('\r\n').map(line => { return (<span>{line}<br></br></span>) })}</p>
                  </Modal>
                </div>
                : null
            )
          }
        }
      ]
    };
  }

  getModalVisible(id) {
    return this.state.visibleMap.get(id) === true ? true : false;
  }

  showModal(id) {
    this.state.visibleMap.set(id, true);
    this.setState({
      visible: true,
    });
  }

  notShowModal(id) {
    this.state.visibleMap.set(id, false);
    this.setState({
      visible: false,
    });
  };

  UpdateJobInfo(id) {
    var path = process.env.REACT_APP_API_URL + "/api/AdHocQuery/Getjobstatus?id=" + id;
    fetch(path, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.props.accessToken
      }
    }).then(
      res => res.json()
    ).then(response => {
      console.log(response);
      var entity = response.Entity;
      if (entity != null) {
        console.log("Response: " + entity);
        var historyData = this.state.historyData;
        for (let i = 0; i < historyData.length; i++) {
          const job = historyData[i];
          if (job.id === id) {
            console.log("Original job: " + job);
            job.status = entity.JobStatus;
            // alert("entity.JobStatus: " + entity.JobStatus + ", entity.Verified: " + entity.Verified)
            job.statusstring = entity.JobStatusString;
            job.joburl = entity.JobUrl;
            job.completetime = entity.CompleteTime;
            if (entity.JobStatus >= 4) {
              var verified = entity.Verified
              job.verified = verified
              if (verified) {
                job.pullrequest = entity.PullRequestLink;
              } else {
                job.errormessage = entity.ErrorMessage;
              }
            }
            this.setState({ historyData: historyData });
            break;
          }
        }
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  state = { visible: false };

  componentWillMount() {
    if (this.props.accessToken != null) {
      var userName = this.props.userName;
      var accessToken = this.props.accessToken;
      var assetName = this.props.assetName;
      this.setState({ historyData: [] });
      this.setState({ submitstatus: '' });
    }
  }

  onDownloadfile = (name) => {
    console.log(name);
    var path = process.env.REACT_APP_API_URL + "/api/Registry/DownloadFile?fileName=" + name;
    fetch(path, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.props.accessToken
      }
    }).then(
      res => res.json()
    ).then(response => {
      console.log(response);
      const element = document.createElement("a");
      const file = new Blob([JSON.stringify(response, null, 4)], {
        type: "application/json"
      });
      element.href = URL.createObjectURL(file);
      element.download = name;
      document.body.appendChild(element);
      element.click();
    }).catch((err) => {
      console.log(err);
    })
  }

  showDrawer = () => {
    let ref = this;
    this.setState({
      visible: true,
      like: null
    });

    this.fileUploadProps = {
      name: 'file',
      action: process.env.REACT_APP_API_URL + "/api/RegistryFile",
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.props.accessToken
      },
      
      onChange(info) {
        if (info.file.status === 'done') {
          var jobRecords = info.file.response.Content.Value;
          console.log("jobRecords:", jobRecords);
          if (jobRecords != null && jobRecords.length > 0) {
            var historyData = ref.state.historyData
            for (let i = 0; i < jobRecords.length; i++) {
              var job = jobRecords[i];
              console.log("job", job);
              var jobRecord = {
                'id': job.ID,
                'username':job.UserName,
                'assetname': job.AssetName,
                'jobid': job.JobId,
                'jobname': job.JobName,
                'runningplatform': job.RunningPlatform,
                'jobtype': job.JobType,
                'jobtypestring': job.JobTypeString,
                'jobstatus': job.JobStatus,
                'jobstatusstring': job.JobStatusString,
                'joburl': job.JobUrl,
                'submittime': job.SubmitTime,
                'completetime': job.CompleteTime,
                'outputpath': job.OutputPath,
                'script': job.Script
              }
              historyData.unshift(jobRecord);
            }
            ref.setState({ historyData: historyData });
          }

          var msg = React.createElement('span', null,
            [React.createElement('span', null, `File [${info.file.name}] uploaded successfully. Waiting for verification...`)
            ]
          );
          message.success(msg, 5);
        } else if (info.file.status === 'error') {
          var msg = React.createElement('span', null,
            [React.createElement('span', null, `File [${info.file.name}] failed to upload.`),
            React.createElement('br'),
            React.createElement('span', null, info.file.response.Message)
            ]
          );
          message.error(msg, 8);
        }
      }
    };

    this.GetRegistryFileList(this.props.accessToken);
    this.GetRegistryHistory(this.props.userName, this.props.accessToken);
  };

  testGET = () => {
    var path = "http://adsdemandcatalogapi.trafficmanager.net/api/DataAssetTree";
    fetch(path, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.props.accessToken
      }
    }).then(
      res => res.json()
    ).then(response => {
      console.log("[testGET] response:", response);
    }).catch((err) => {
      console.log(err);
    })
  }

  onClose = () => {
    this.setState({
      visible: false,
    });
    this.props.form.resetFields();
  };

  onSubmit = e => {
    if (this.state.like == null)
      return;
    e.preventDefault();
    /*
    this.props.form.validateFieldsAndScroll((err, values) => {
        this.SaveFeedback(this.state.like, values.feedback)
    });
    */
    this.onClose();
  };

  GetRegistryFileList(accessToken) {
    var path = process.env.REACT_APP_API_URL + "/api/RegistryFileList";
    fetch(path, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.props.accessToken
      }
    }).then(
      res => res.json()
    ).then(response => {
      if (response) {
        let data = [];
        for (let i = 0; i < response.length; i++) {
          data.push({ key: i, name: response[i] })
        }
        this.setState({
          fileList: response,
          data: data,
        });
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  GetRegistryHistory(userName, accessToken) {
    var path = process.env.REACT_APP_API_URL + "/api/Registry/GetSubmitHistory?userName=" + userName;
    fetch(path, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.props.accessToken
      }
    }).then(
      res => res.json()
    ).then(response => {
      if (response) {
        var entity = response.Entity;
        if (entity != null) {
          var historyData = [];
          for (let i = 0; i < entity.length; i++) {
            const job = entity[i];
            historyData[i] = {
              'id': job.ID,
              'username': job.UserName,
              'submittime': job.SubmitTime,
              'status': job.JobStatus,
              'statusstring': job.JobStatusString,
              'joburl': job.JobUrl,
              'verified': job.Verified,
              'completetime': job.CompleteTime,
              'pullrequest': job.PullRequestLink,
              'errormessage': job.ErrorMessage,
            };
          }
          this.setState({ historyData: historyData });
        }
      }
    }).catch((err) => {
      console.log(err);
    })
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <>
        <Tooltip title="Data Onboard">
          <ImportOutlined style={{ fontSize: 20, color: '#fff', marginRight: '8px' }} onClick={this.showDrawer} />
        </Tooltip>
        {/* <Tooltip title="Experimental">
          <ImportOutlined style={{ fontSize: 20, color: '#fff', marginRight: '8px' }} onClick={this.testGET} />
        </Tooltip> */}
        <Drawer
          title="Onboard DataRegistry"
          width={1000}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          {/* Upload Button */}
          <div><span>Onboard DataRegistry</span>
            <Upload {...this.fileUploadProps}>
              <Button icon={<UploadOutlined />} style={{ marginLeft: '200px' }}>Import new json file</Button>
            </Upload>
            <br /><br />
          </div>

          {/* Onboard history */}
          <div><span>Onboard History</span>
            <Table columns={this.state.registry_change_history_columns} dataSource={this.state.historyData} locale={{ emptyText: 'Loading...' }} />
          </div>

          {/* Onboarded file list */}
          <div><span>Onboarded files</span>
            <Table columns={this.state.registry_file_list_columns} dataSource={this.state.data} locale={{ emptyText: 'Loading...' }} />
          </div>
          {/* <Button onClick={this.onClose} style={{ marginRight: 8 }}>Cancel</Button> */}
          {/* <div
            style={{
              position: 'absolute',
              right: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
          </div> */}
        </Drawer>
      </>
    );
  }
}

export default Form.create({ name: 'tools' })(Tools);
import React from 'react';

class Sample extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            samples: [],
            columns: [],
        };
        this.assetType = this.GetAssetType(this.props.assetName);
        this.GetSamples(this.props.accessToken, this.props.assetName, this.assetType);
    }

    GetAssetType(assetName) {
        var assetType = "";
        if (assetName.startsWith("DataRegistry(")) {
            assetType = "DataRegistrySample";
        } else if (assetName.startsWith("C2SView(")) {
            assetType = "C2SSample";
        }
        return assetType;
    }

    GetSamples(accessToken, assetName, assetType) {
        var path = process.env.REACT_APP_API_URL+"/api/DataAssetSample?type="+assetType+"&name="+assetName;
        fetch(path, {
          method:'GET',
          headers:{
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer ' + accessToken
          }
        }).then(
            res=>res.json()
        ).then(response=>{
            if (response && response.Samples){
                this.setState({ 
                    samples: response.Samples,
                    columns: Object.keys(response.Samples[0]).map(value => ({
                        key: value,
                        label: value,
                      })),
                });
            }
        }).catch((err)=>{
          console.log(err);
        })
    }

    render() {
        const rows = this.state.samples.map((item, index) => {
            const cells = this.state.columns.map(colData => {
              return <td style={{border:'1px solid black', padding: '6px'}} key={colData.key}>{item[colData.key]}</td>;
            });
        
            return <tr key={index}>{cells}</tr>;
          });

        return (
            <div  style={{overflowX: 'auto'}} >
            <table style={{border:'1px solid black', textAlign:'center', borderCollapse: 'collapse'}}>
                <thead>
                    <tr>
                        {this.state.columns.map(colData => (
                            <th style={{border:'1px solid black' , padding: '6px'}} key={colData.key}>{colData.label}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
            </div>
            )
    }
}

export default Sample;
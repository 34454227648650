import 'd3-transition';
import { select } from 'd3-selection';
import React from 'react';
import ReactWordcloud from 'react-wordcloud';

class WordCloud extends React.Component {

  getCallback(callback) {
    return function(word, event) {
      const isActive = callback !== 'onWordMouseOut';
      const element = event.target;
      const text = select(element);
      text
        .on('click', () => {
          if (isActive && word.jobname.length>0) {
            var jobName = JSON.parse(word.jobname)[0].replace(":::","");
            var searchtext = word.assetname+","+word.text;
            window.open(`https://scriptlibrary.azurewebsites.net/CosmosScript?searchText=${searchtext}+jobname:${jobName}&source=main&pageNumber=1&tenant=all&orderByLatest=on`, '_blank');
          }
        })
        .transition()
        .attr('background', 'white')
        .attr('text-decoration', isActive ? 'underline' : 'none');
    };
  };
  
  callbacks = {
    onWordClick: this.getCallback('onWordClick'),
    onWordMouseOut: this.getCallback('onWordMouseOut'),
    onWordMouseOver: this.getCallback('onWordMouseOver'),
  };
    
    render() {
        var words = this.props.words;
  
        const options = {
          //colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b'],
          enableTooltip: false,
          deterministic: false,
          //fontFamily: 'impact',
          fontSizes: [15, 40],
          fontStyle: 'normal',
          fontWeight: 'normal',
          padding: 1,
          rotations: 1,
          rotationAngles: [0, 90],
          scale: 'sqrt',
          spiral: 'archimedean',
          transitionDuration: 1000,
        };

      return (
        <ReactWordcloud callbacks={this.callbacks} options={options} words={words} />
      );
    }
  }
  
  
export default WordCloud;
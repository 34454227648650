import 'd3-transition';
import React from 'react';
import Diagram from './Diagram';
import RelationshipTable from './relationshiptable';

class RelationShip extends React.Component {
    
    render() {
        var words = this.props.words;
        var relations = this.props.relations;
        var assetName = this.props.assetName;
        var width = window.innerWidth-350;
        var tableWidth = width*0.5;

      return (
        <div>
          <div style={{width:tableWidth, float:'left'}}>
            <RelationshipTable relations={relations} assetName={assetName} tableWidth={tableWidth}/>
          </div>
          <div style={{width:width*0.5, float:'left'}}>  
            <Diagram relations={relations} words={words} assetName={assetName}/>
          </div> 
        </div>
      );
    }
  }
  
  
export default RelationShip;
import React from 'react';
import ResizeTable from '../resizetable';
import { Button, Modal } from 'antd';
import LoadingButton from '../button';



class JobHistoryTable extends React.Component {
  constructor(props) {
    super(props);
    var assetName = this.props.assetName;
    console.log(assetName);
    var isC2S = (assetName != null && (assetName.startsWith("C2SView")
      || assetName.startsWith("DataRegistry") || assetName.startsWith("RSAAdsDrop")
      || assetName.startsWith("AdsDropDebugging") || assetName.startsWith("SpendByOperation"))) ? true : false;
    var timeoutMap = {};
    this.state = {
      previewcolumns: [],
      previewdata: [],
      jobname: '',
      visibleMap: new Map(),
      visible: false,
      // No "Script" for C2S.
      columns: isC2S ? [
        {
          title: 'Submit Time',
          dataIndex: 'submit_time',
          key: 'submit_time',
          width: '17.5%',
          sorter: (a, b) => a.submittime > b.submittime
        }, {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
          width: '45%'
        }, {
          title: 'Status',
          dataIndex: 'job_status_string',
          key: 'job_status_string',
          width: '17.5%'
        }, {
          title: 'View Result',
          dataIndex: 'sampleview',
          key: 'sampleview',
          width: '10%',
          render: (text, record) => {
            // 0: submitted, 1: running, 2: succeeded, -1: failed
            var status = record.job_status;
            if (status != null) {
              // 4: COMPLETED, 5: COMPLETED_SUCCESS. 4 is for C2S query job, 5 is for C2C query job.
              if (status === 4 || status === 5) {
                // 0: C2C, ULD, ULP: C2CQuery; 1: C2S, DataRegistry: BingMT
                if (record.running_platform === 0) {
                  return (
                    <div>
                      <LoadingButton text={"Preview"} style={{ width: '135px' }} onClick={() => this.GetC2CSampleView(record.job_name, record.output_path)} />
                      <LoadingButton text={"Download Result"} style={{ width: '135px' }} onClick={() => this.DownloadResult(record.id, this.props.assetName + "_" + record.submit_time + ".csv")} />
                    </div>
                  )
                } else {
                  if (!record.asset_name.startsWith("RSAAdsDrop-Stage1")) {
                    return (
                      <div>
                        <LoadingButton text={"Preview"} style={{ width: '135px' }} onClick={() => this.GetC2SSampleView(record.job_name, record.asset_name, record.output_path)} />
                        <LoadingButton text={"Download Result"} style={{ width: '135px' }} onClick={() => this.DownloadResult(record.id, this.props.assetName + "_" + record.submit_time + ".csv")} />
                      </div>
                    )
                  } else {
                    return (
                      <div>
                        <LoadingButton text={"Download Result"} style={{ width: '135px' }} onClick={() => this.DownloadResult(record.id, this.props.assetName + "_" + record.submit_time + ".csv")} />
                      </div>
                    )
                  }
                }
              }
              if (status === 1 || status === 2 || status === 3) {
                if (timeoutMap[record.id] == null) {
                  console.log("Creating interval for job: " + record.id);
                  timeoutMap[record.id] = window.setInterval(() => this.props.UpdateJobInfo(record.id), 15000);
                }
              } else {
                if (timeoutMap[record.id] != null) {
                  console.log("Removing interval of job: " + record.id);
                  window.clearInterval(timeoutMap[record.id]);
                }
              }
              if (record.running_platform === 0) {
                if (status === 1 || status === 2 || status === 3) {
                  return (
                    <LoadingButton text={"Cancel Job"} onClick={() => this.CancelJob(record.id)} />
                  )
                }
              }
            }
          }
        }, {
          title: 'Job Link',
          dataIndex: 'job_url',
          key: 'job_url',
          width: '10%',
          render: (text, record) => {
            if (record.job_url != null && record.job_url != "") {
              return (
                <span><a href={record.job_url} target={"_blank"}>Link</a></span>
              )
            } else {
              return 'N/A'
            }
          }
        }
      ] : [{
        title: 'Submit Time',
        dataIndex: 'submit_time',
        key: 'submit_time',
        width: '17.5%',
        sorter: (a, b) => a.submittime > b.submittime
      }, {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: '35%'
      }, {
        title: 'Status',
        dataIndex: 'job_status_string',
        key: 'job_status_string',
        width: '17.5%'
      }, {
        title: 'View Result',
        dataIndex: 'sampleview',
        key: 'sampleview',
        width: '10%',
        render: (text, record) => {
          // 0: submitted, 1: running, 2: succeeded, -1: failed
          var status = record.job_status;
          if (status != null) {
            // 4: COMPLETED, 5: COMPLETED_SUCCESS. 4 is for C2S query job, 5 is for C2C query job.
            if (status === 4 || status === 5) {
              // 0: C2C, ULD, ULP: C2CQuery; 1: C2S, DataRegistry: BingMT
              if (record.running_platform === 0) {
                return (
                  <div>
                    <LoadingButton text={"Preview"} onClick={() => this.GetC2CSampleView(record.job_name, record.output_path)} />
                    <LoadingButton text={"Download Result"} onClick={() => this.DownloadResult(record.id, this.props.assetName + "_" + record.submit_time + ".csv")} />
                  </div>
                )
              } else {
                if (!record.asset_name.startsWith("RSAAdsDrop-Stage1")) {
                  return (
                    <div>
                      <LoadingButton text={"Preview"} onClick={() => this.GetC2SSampleView(record.job_name, record.asset_name, record.output_path)} />
                      <LoadingButton text={"Download Result"} onClick={() => this.DownloadResult(record.id, this.props.assetName + "_" + record.submit_time + ".csv")} />
                    </div>
                  )
                } else {
                  return (
                    <div>
                      <LoadingButton text={"Download Result"} onClick={() => this.DownloadResult(record.id, this.props.assetName + "_" + record.submit_time + ".csv")} />
                    </div>
                  )
                }
              }
            }
            if (status === 1 || status === 2 || status === 3) {
              if (timeoutMap[record.id] == null) {
                console.log("Creating interval for job: " + record.id);
                timeoutMap[record.id] = window.setInterval(() => this.props.UpdateJobInfo(record.id), 15000);
              }
            } else {
              if (timeoutMap[record.id] != null) {
                console.log("Removing interval of job: " + record.id);
                window.clearInterval(timeoutMap[record.id]);
              }
            }
            if (record.running_platform === 0) {
              if (status === 1 || status === 2 || status === 3) {
                return (
                  <LoadingButton text={"Cancel Job"} onClick={() => this.CancelJob(record.id)} />
                )
              }
            }
          }
        }
      }, {
        title: 'Job Link',
        dataIndex: 'job_url',
        key: 'job_url',
        width: '10%',
        render: (text, record) => {
          if (record.job_url != null && record.job_url != "") {
            return (
              <span><a href={record.job_url} target={"_blank"}>Link</a></span>
            )
          } else {
            return 'N/A'
          }
        }
      }, {
        title: 'Script',
        dataIndex: 'script',
        key: 'script',
        width: '10%',
        render: (text, record) => {
          var job_id = record.job_id;
          var script = record.script;
          var show = script != null && script.length > 0;
          return (
            show ?
              <div>
                <Button onClick={() => this.showModal(job_id)}>
                  View Script
                </Button>
                <Modal
                  title="Script Content"
                  centered
                  width={1000}
                  visible={this.state.visibleMap.get(job_id) === true ? true : false}
                  onOk={() => this.notShowModal(job_id)}
                  onCancel={() => this.notShowModal(job_id)}
                >
                  <p>{script.split('\r\n').map(line => { return (<span>{line}<br></br></span>) })}</p>
                </Modal>
              </div>
              : null
          )
        }
      }
      ]
    };
  }

  getModalVisible(jobid) {
    return this.state.visibleMap.get(jobid) === true ? true : false;
  }

  showModal(jobid) {
    this.state.visibleMap.set(jobid, true);
    this.setState({
      visible: true,
    });
  }

  notShowModal(jobid) {
    this.state.visibleMap.set(jobid, false);
    this.setState({
      visible: false,
    });
  };

  async GetC2SSampleView(jobName, assetName, resultLink) {
    var path = process.env.REACT_APP_API_URL + "/api/AdHocQuery/GetC2SSampleResult?assetName=" + assetName + "&outputPath=" + encodeURIComponent(resultLink);
    await fetch(path, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.props.accessToken
      }
    }).then(
      res => res.json()
    ).then(response => {
      var entity = response.Entity;
      console.log("[jobhistorytable::GetC2SSampleView] Preview data", entity)
      if (entity != null && entity.length > 0) {
        if ("RSAAdsDrop-Stage0" === assetName || "AdsDropDebugging-Stage0" === assetName) {
          var columnNames = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q"];
          var columns = [];
          for (let i = 0; i < columnNames.length; i++) {
            var columnName = columnNames[i]; // A, B, C...
            columns[i] = {
              title: columnName, // A, B, C...
              dataIndex: columnName, // A, B, C...
              key: columnName // A, B, C...
            };
          }
          this.setState({ previewcolumns: columns });
          this.setState({ jobname: jobName });
          var previewdata = [];
          for (let i = 0; i < entity.length; i++) {
            var row = entity[i];
            var obj = {};
            for (let j = 0; j < row.length; j++) {
              try {
                obj[columns[j].key] = row[j]; // obj["A"] = ..., obj["B"] = ...
                // obj[columns[j].key] = "<br>" + row[j] + "</br>"; // obj["A"] = ..., obj["B"] = ...
              } 
              catch (err) {
                console.error("[JobHistoryTable::GetC2SSampleView] Errors that can be ignored:", err);
              }
            }
            previewdata.push(obj);
          }
          this.setState({ previewdata: previewdata });
        } else {
          var columns = [];
          for (let i = 0; i < entity[0].length; i++) {
            var column = entity[0][i];
            columns[i] = {
              title: column,
              dataIndex: column,
              key: column,
              //width: columnWidth
            };
          }
          this.setState({ previewcolumns: columns });
          this.setState({ jobname: jobName });
          var previewdata = [];
          for (let i = 1; i < entity.length; i++) {
            var row = entity[i];
            var obj = {};
            for (let j = 0; j < row.length; j++) {
              obj[columns[j].key] = row[j];
            }
            previewdata.push(obj);
          }
          this.setState({ previewdata: previewdata });
        }
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  async GetC2CSampleView(jobname, resultLink) {
    var path = process.env.REACT_APP_API_URL + "/api/AdHocQuery/GetC2CSampleResult?StreamPath=" + encodeURIComponent(resultLink);
    await fetch(path, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.props.accessToken
      }
    }).then(
      res => res.json()
    ).then(response => {
      var entity = response.Entity;
      if (entity != null && entity.length > 0) {
        // var width = window.innerWidth - 400;
        // var tableWidth = width*0.6;
        // var columnWidth = tableWidth/entity[0].length;
        var columns = [];
        for (let i = 0; i < entity[0].length; i++) {
          var column = entity[0][i];
          columns[i] = {
            title: column,
            dataIndex: column,
            key: column,
            //width: columnWidth
          };
        }
        this.setState({ previewcolumns: columns });
        this.setState({ jobname: jobname });

        var previewdata = [];
        for (let i = 1; i < entity.length; i++) {
          var row = entity[i];
          var obj = {};
          for (let j = 0; j < row.length; j++) {
            obj[columns[j].key] = row[j];
          }
          previewdata.push(obj);
        }
        this.setState({ previewdata: previewdata });
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  DownloadResult = async (id, fileName) => {
    var path = process.env.REACT_APP_API_URL + "/api/AdHocQuery/DownloadJobResult?id=" + id;
    await fetch(path, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.props.accessToken
      }
    })
      .then(response => response.blob())
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a); // We need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();  // Afterwards we remove the element again         
      });
  }

  CancelJob(jobid, status) {
    var path = process.env.REACT_APP_API_URL + "/api/AdHocQuery/CancelJob?jobId=" + jobid;
    fetch(path, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.props.accessToken
      }
    }).then(
      this.props.UpdateJobInfo(jobid)
    ).catch((err) => {
      console.log(err);
    })
  }

  componentDidMount() {
    this.setState({ accessToken: this.props.accessToken });
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.assetName !== this.props.assetName) {
      this.setState({ previewdata: [] });
      this.setState({ previewcolumns: [] });
    }
  }

  render() {
    var historyData = this.props.historyData;
    var previewcolumns = this.state.previewcolumns;
    var previewData = this.state.previewdata;
    var assetName = this.props.assetName;
    // var isRSAAdsDrop = assetName.startsWith("RSA");
    var previewLines = assetName.startsWith("RSA") ? 28 : (assetName.startsWith("AdsDropDebugging-Stage0") ? 53 : 20);
    var showHeader = (assetName.startsWith("RSA") || assetName.startsWith("AdsDropDebugging-Stage0")) ? false : true;

    return (
      <div>
        <ResizeTable
          name={this.props.assetName}
          data={historyData}
          columns={this.state.columns}
          pageSize={10}
          position={'bottom'}
        />
        {
          previewcolumns.length !== 0 && previewData.length === 0 ?
            <div>
              <span style={{ fontSize: 18 }}>No data of job {this.state.jobname}</span>
              <br /><br /><br />
            </div>
            : null
        }
        {
          previewcolumns.length !== 0 && previewData.length !== 0 ?
            <div>
              <span style={{ fontSize: 18 }}>Top {previewLines} rows of job {this.state.jobname}:</span>
              <ResizeTable
                name={this.state.jobname}
                data={previewData}
                columns={previewcolumns}
                pageSize={previewLines}
                position={'none'}
                scrollx={previewcolumns.length * 200}
                scrolly={400}
                showHeader={showHeader}
              />
              <br />
            </div>
            : null
        }
      </div>

    );
  }
}

export default JobHistoryTable;
import 'd3-transition';
import React from 'react';
import ColumnUsageTable from './columnusagetable';
import Wordcloud from './WordCloud';

class ColumnUsage extends React.Component {

    render() {
        var words = this.props.words;
        var assetname = this.props.assetName;
        var width = window.innerWidth - 400;
        var tableWidth = width*0.6;

      return (
        <div>
            <div style={{width:tableWidth, float:'left'}}>  
                <ColumnUsageTable words={words} assetName={assetname} columnTitle='Column' pageSize={5} tableWidth={tableWidth}/>
            </div>
            <div style={{ width:width*0.4, float:'left' }}>  
                <Wordcloud words={words.slice(0, words.length > 30 ? 30 : words.length)} />
            </div>
        </div>
      );
    }
  }
  
  
export default ColumnUsage;
import React from 'react';
import { Tree, Input, Spin } from 'antd';

const { TreeNode, DirectoryTree } = Tree;
const { Search } = Input;


const getParentKey = (key, tree) => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.Children) {
      if (node.Children.some(item => item.Path === key)) {
        parentKey = node.Path;
      } else if (getParentKey(key, node.Children)) {
        parentKey = getParentKey(key, node.Children);
      }
    }
  }
  return parentKey;
};

class SearchTree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedKeys: [],
      searchValue: '',
      autoExpandParent: true,
      gData: [],
      dataList: []
    };
    this.flag = 0;
  }

  generateList(data) {
    for (let i = 0; i < data.length; i++) {
      const node = data[i];
      // const { key } = node;
      this.state.dataList.push({ key: node.Path, title: node.Name });
      if (node.Children) {
        this.generateList(node.Children);
      }
    }
  }

  getData() {
    // console.log("[tree::getData] accessToken:", this.props.accessToken)
    var path = process.env.REACT_APP_API_URL + "/api/DataAssetTree";
    fetch(path, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.props.accessToken
      }
    }).then(
      res => res.json()
    ).then(response => {
      console.log("[tree::getData] response:", response);
      this.setState({ gData: response });
      this.generateList(this.state.gData);
    }).catch((err) => {
      console.log(err);
    })
  };

  addUserActivity(viewName) {
    var path = process.env.REACT_APP_API_URL + "/api/UserActivity";
    var data = {
      'UserName': this.props.userName,
      'ViewName': viewName
    }
    fetch(path, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.props.accessToken
      },
      "body": JSON.stringify(data)
    }).catch((err) => {
      console.log(err);
    })
  };

  componentWillUpdate() {
    if (this.flag === 0 && this.props.accessToken != null) {
      this.getData();
      this.addUserActivity(null);
      this.flag = 1;
    }
  }

  onSelect = async (keys, event) => {
    var assetName = event.selectedNodes[0].props.fullname;
    if (assetName != null && assetName.startsWith("Data Analysis")) {
      this.props.getCustomizedC2SReport(assetName);
    } else if (assetName != null && assetName.startsWith("RSA")) {
      this.props.getRSAAdsDrop(assetName);
    } else if (assetName != null && assetName.startsWith("AdsDropDebugging")) {
      this.props.getAdsDropDebugging(assetName);
    } else if (assetName != null && assetName.startsWith("SpendByOperation")) {
      this.props.getSpendByOperation(assetName);
    } else {
      await this.props.changeItem(keys[0]);
      this.props.getWordsPopularity(assetName); // /api/DataAssetRelation/Column?assetName=
      this.props.getFilterPopularity(assetName);  // /api/DataAssetRelation/Filter?assetName=
      this.props.getRelationShip(assetName); // /api/DataAssetRelation?assetName=
      this.addUserActivity(assetName);
    }
    console.log('Trigger Select', keys, event);
  };

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };

  onChange = e => {
    var value = e.target.value.toLowerCase();
    const expandedKeys = this.state.dataList
      .map(item => {
        if (item.title.toLowerCase().indexOf(value) > -1) {
          return getParentKey(item.key, this.state.gData);
        }
        return null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);
    this.setState({
      expandedKeys,
      searchValue: value,
      autoExpandParent: true,
    });
  };

  render() {
    const { searchValue, expandedKeys, autoExpandParent } = this.state;
    const loop = data =>
      data.map(item => {
        const index = item.Name.toLowerCase().indexOf(searchValue);
        const beforeStr = item.Name.substr(0, index);
        const afterStr = item.Name.substr(index + searchValue.length);
        const currentValue = item.Name.substr(index, searchValue.length);
        const title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span style={{ color: '#f50' }}>{currentValue}</span>
              {afterStr}
            </span>
          ) : (
            <span>{item.Name}</span>
          );

        if (item.Children) {
          return (
            <TreeNode key={item.Path} title={title} name={item.Name} fullname={item.FullName} style={{ color: '#fff' }}>
              {loop(item.Children)}
            </TreeNode>
          );
        }
        if (index > -1) {
          return <TreeNode key={item.Path} title={title} name={item.Name} fullname={item.FullName} isLeaf />;
        }
        else {
          return null;
        }
      });
    return (
      <div>
        <Search style={{ marginBottom: 8 }} placeholder="Search Asset Name" onChange={this.onChange} />
        {
          this.state.gData.length === 0 ?
            <div style={{ textAlign: "center", marginLeft: "auto", marginRight: "auto" }}>
              <Spin tip="Loading..." />
            </div>
            :
            <DirectoryTree
              onExpand={this.onExpand}
              onSelect={this.onSelect}
              expandedKeys={expandedKeys}
              autoExpandParent={autoExpandParent}
            >
              {loop(this.state.gData)}
            </DirectoryTree >
        }
      </div>
    );
  }
}

export default SearchTree;